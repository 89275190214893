.faqContainer {
  color: white;
  display: grid;
  place-items: center;
}

.faqContent {
  width: 55%;
  display: grid;
  place-items: center;
}

.faqContainer .faqContent h2 {
  font: normal normal bold 1.55vw / 3.1vw Helvetica Neue;
  letter-spacing: 0.23vw;
}

.faqContainer .faqContent p {
    width: 80%;
    text-align: center;
}

.faqContainer .faqContent input {
    width: 60%;
    border-radius: 10px;
    padding: 1.5%;
    background-color: #23252D;
    border: none;
    color: white;
}

.faqContainer .faqContent input::-webkit-input-placeholder {
    color: #555558;
}

.faqContainer .faqContent p,
.faqContainer .faqContent input {
  font-size: 1.04vw;
  line-height: 1.5vw;
  letter-spacing: 0.06vw;
}
