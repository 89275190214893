

 .scienceApp {
    padding-top: 10%;
    padding-bottom: 10%;
    width: 100%;
    background: #282c34;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    overflow: hidden;
}

.cards-table {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;
}

 .card {
    position: relative;
    width: 20vh;
    height: 20vh;
    /* overflow: hidden; */
    border: 1px solid #ddd;
    border-radius: 4px;
}
 .card img {
    width: 100%;
    transition: transform 0.3s ease;
    height: 100%;
    object-fit: cover;
}

.card:hover img {
    transform: scale(1.1);
}

 .hover-text {
    position: absolute;
    bottom: 11%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;

    /* Updated styles to make the div expand based on content size */
    max-width: 200%;
    /* Limit the width to its parent's width */
    overflow: hidden;
    /* Hide overflowing content */

    /* Prevent text from wrapping to the next line */
    white-space: nowrap;



}

 .card:hover .hover-text {
    position: absolute;
    visibility: visible;
    opacity: 1;
    /* width: 40vw; */


}

.card .hover-text:hover .card {
    overflow: visible !important;
    background-color: black;
}

@media only screen and (max-width: 800px) {
     .cards-table {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .ScienceAplicationblock {
        width: 100%;
        /* margin-top: 5% !important; */
    }
  
    /* .sciAppContainer .scienceApp{
        padding-top: 20%;
    } */

}
@media only screen and (max-width: 1025px) {
 .cards-table {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
    }
}

@media only screen and (max-width: 500px) {
   .cards-table {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
    .scienceApp {
        padding-top: 20%;
    }


.Scinav{
        height: 48px !important;
    }
    .ScienceAplicationblock {
        height: 100% !important;
    }
}



