
.eleGames .gmContainer {
    height: 100vh;
    width: auto;
    display: flex;
    background-image: linear-gradient(179deg, #00010e, #010415);
    /* flex-wrap: wrap; */
}

.eleGames .gmImg {
    background-color: #212e50;
    padding: 50px;
    /* position: absolute; */
    top: 0;
    justify-content: space-evenly;
    /* background-image:linear-gradient(#173254), url("/public/GamingZone/gamingZone.png"); */
    background-image: url("/public/GamingZone/gamingZoneNew.png");
    width: 50%;
    height: auto;
    background-size: cover;
    /* background-size: 90% auto; */
    background-repeat: no-repeat;
    color: white;
    background-position: center;
}

.eleGames .para {
    display: flex !important;
    flex-direction: column;
    display: flex;
    width: 25vw;
    font-size: 0.94vw;
    /* width: 100%;
    gap: 2rem; */
    /* margin-top: 15%; */
}

.eleGames .gmTag {
    font-family: "DM Sans", Sans-serif;
    flex-direction: column;
    display: flex;
    width: 50%;
    /* gap: 10%; */
    margin-top: 15%;
    padding: 5%;
    height: auto;

}


/*.ExploreBtn*/
.eleGames .heading,
.eleGames .para {
    height: auto;
    z-index: 1;
    color: aliceblue;
}

.eleGames .heading h1 { 
    font-size: 4.4vw;
}

.eleGames .explore {
    height: 50px !important;
    gap: 5px;
}

.eleGames .gaminZoneButtonContainer {
    z-index: 1;
}

.eleGames .options {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    min-width: 300px;
    /* max-width: 900px; */
    width: calc(100% - 100px);
    width: 60vw;
    height: 46vh;
}

/* Each Option Image will display after the --optionBackround Variable created */
.eleGames .options .option {
    position: relative;
    overflow: hidden;
    min-width: 60px;
    height: auto;
    margin: 5px;
    background: var(--optionBackground, var(--defaultBackground, #e6e9ed));
    background-size: auto 120%;
    background-position: center;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}

.eleGames #prithvi {
    background-image: url("/public/GamingZone/prithvi.png");
}

.eleGames #jala {
    background-image: url("/public/GamingZone/jala.png");
}

.eleGames #vayu {
    background-image: url("/public/GamingZone/vayu.png");
}

.eleGames #tejas {
    background-image: url("/public/GamingZone/tejas.png");
}

.eleGames #akash {
    background-image: url("/public/GamingZone/akash.png");
}

.eleGames #prithvi,
.eleGames #jala,
.eleGames #vayu,
.eleGames #tejas,
.eleGames #akash {
    background-repeat: no-repeat;
    background-size: cover;
}

.eleGames #prithvi .icon i {
    color: #476a63;
}

.eleGames #jala .icon i {
    color:#297799;
}

.eleGames #vayu .icon i {
    color: #f3ba7f;
}

.eleGames #tejas .icon i {
    color: #c43712;
}

.eleGames #akash .icon i {
    color: #1e223d;
}

/* 
.eleGames .options .option:hover {
    flex-grow: 10000;
    transform: scale(1);
    max-width: 600px;
    margin: 0px;
    border-radius: 40px;
    background-size: auto 100%;
} */

.eleGames .options .option.active {
    flex-grow: 10000;
    transform: scale(1);
    /* max-width: 900px; */
    max-width: 1192px;
    margin: 0px;
    border-radius: 40px;
    background-size: auto 100%;
}

.eleGames .options .option.active .shadow {
    box-shadow: inset 0 -120px 120px -120px black,
        inset 0 -120px 120px -120px black;
}

.eleGames .options .option.active .label {
    bottom: 20px;
    left: 20px;
}

.eleGames .options .option.active .label .info>div {
    left: 5px;
    opacity: 1;
}

.eleGames .options .option:not(.active) {
    flex-grow: 1;
    border-radius: 30px;

}

.eleGames .options .option:not(.active) .shadow {
    bottom: -40px;
    box-shadow: inset 0 -120px 120px -120px black, inset 0 -120px 0px -100px black;
}

.eleGames .options .option:not(.active) .label {
    bottom: 10px;
    left: 10px;
}

.eleGames .options .option:not(.active) .label .info>div {
    left: 0px;
    opacity: 0;
}

.eleGames .options .option .shadow {
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 120px;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}

.eleGames .options .option .label {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    height: 3vw;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}

.eleGames .options .option .label .icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 2vw;
    max-width: 2vw;
    height: 2vw;
    border-radius: 100%;
    background-color: white;
    color: var(--defaultBackground);
    font-size: 1vw;
}

.eleGames .options .option .label .icon i {
    height: min-content;
}

.eleGames .options .option .label .info {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    /* margin-left: 10px; */
    color: white;
    white-space: pre;
}

.eleGames .options .option .label .info>div {
    position: absolute;
    transition: 0.5s cubic-bezier(0.05, 0.061, 0.41, 0.95) opacity 0.5s ease-out;
}

.eleGames .options .option .label .info .main {
    font-weight: bold;
    font-size: 1.2rem;
}

.eleGames .gmContainer2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #010415;
    height: 100vh;
}

.eleGames .gameLink {
    gap: 0.2vw;
}

.eleGames .link {
    width: 10px;
    display: contents;
    font-size: small;
}

@media only screen and (max-width: 990px) {
    .eleGames .gmTag {
        gap: 10%;
    }
}
@media only screen and (max-width: 768px) {
    .eleGames .heading h1 {
        font-size: 50px;
    }
}

@media only screen and (max-width: 620px) {
    .eleGames .options {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;
        height: 80%;
        /* set the height of the parent container */
        width: 75%;
    }

    /* Each Option Image will display after the --optionBackground Variable created */
    .eleGames .options .option {
        position: relative;
        overflow: hidden;
        height: 60px;
        /* set height instead of width because we changed flex direction to column */
        margin: 10px;
        background: var(--optionBackground, var(--defaultBackground, #e6e9ed));
        background-size: 120% auto;
        /* change to auto 120% because we changed flex direction to column */
        background-position: center;
        cursor: pointer;
        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    }

    .eleGames .options .option.active {
        flex-grow: 10000;
        transform: scale(1);
        max-height: 600px;
        /* set max-height instead of max-width because we changed flex direction to column */
        margin: 0px;
        border-radius: 40px;
        background-size: 100% auto;
        /* change to auto 100% because we changed flex direction to column */
    }


}


@media screen and (max-width: 450px) {
    .eleGames .heading h1 {
        font-size: 46px;
    }

    .eleGames .para p {
        width: 290px;
    }

    .eleGames .gmImg {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("/public/GamingZone/gamingZoneMobile.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat
    }
    .eleGames .gmContainer2{
        background: linear-gradient(to top, #0d212e 0%, black 100%);
    }
}