@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

* {
  font-family: 'DM Sans', sans-serif;
  /* height: 0px; */
}


.spaceGameContent {
  background-color: black;
  color: aliceblue;
  height: 100vh;
  overflow: hidden;
}


.spaceGameContent .buttonsSpace {
  position: relative;
  display: flex;
  gap: 2rem;
  top: 30vh;
}
.spaceGameContent .buttonsSpace button a {
  color: #000;
  text-decoration: none;
}
.spaceGameContent .buttonsSpace button {
  border-radius: 20px;
  padding: 8px;
  width: 158px;
  height: max-content;
  border: none;
  cursor: pointer;
  color: #000;
  font-weight: bold;
}

.spaceGameContent .buttonsSpace button:nth-child(1):hover {
  box-shadow: 0 0 4px 4px #02db02;
  background-image: radial-gradient(white, #e5cfcf, #02db02);
}

.spaceGameContent .buttonsSpace button:nth-child(2):hover {
  box-shadow: 0 0 4px 4px #ff3700;
  background-image: radial-gradient(white, #e5cfcf, #ff3700);
}




.spaceGameContent .game {
  position: absolute;
  height: 100%;
  filter: blur(20px);
}

.spaceGameContent .game,
.spaceGameContent .controls {
  display: flex;
  justify-content: center;
}

.spaceGameContent #gameCanvas {
  width: 97%;
  height: 100%;
}

.spaceGameContent .loading {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: black;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url("./loadingBackground.png");
  background-position: center;
}

.spaceGameContent .titleSpace {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20%;
  text-align: center;
  width: 100%;
  height: 100px;
  background-image: url('./title.png');
  width: 60%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.spaceGameContent .loading-text {
  position: absolute;
  /* top: 0; */
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100px;
  line-height: 100px;
}

.spaceGameContent .loading-text span {
  display: inline-block;
  margin: 0 5px;
  color: #fff;
  font-family: "Quattrocento Sans", sans-serif;
}

.spaceGameContent .loading-text span:nth-child(1) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0s infinite linear alternate;
  animation: blur-text 1.5s 0s infinite linear alternate;
}

.spaceGameContent .loading-text span:nth-child(2) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.2s infinite linear alternate;
  animation: blur-text 1.5s 0.2s infinite linear alternate;
}

.spaceGameContent .loading-text span:nth-child(3) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.4s infinite linear alternate;
  animation: blur-text 1.5s 0.4s infinite linear alternate;
}

.spaceGameContent .loading-text span:nth-child(4) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.6s infinite linear alternate;
  animation: blur-text 1.5s 0.6s infinite linear alternate;
}

.spaceGameContent .loading-text span:nth-child(5) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.8s infinite linear alternate;
  animation: blur-text 1.5s 0.8s infinite linear alternate;
}

.spaceGameContent .loading-text span:nth-child(6) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 1s infinite linear alternate;
  animation: blur-text 1.5s 1s infinite linear alternate;
}

.spaceGameContent .loading-text span:nth-child(7) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}

@-webkit-keyframes blur-text {
  0% {
    filter: blur(0px);
  }

  100% {
    filter: blur(4px);
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }

  100% {
    filter: blur(4px);
  }
}

.spaceGameContent .gameOver {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.spaceGameContent .gameOver div p {  
  position: relative;
  font-size: large;
  font-family: DM-Sans;
  font-weight: bold;
  color: #b5b4b3;
  bottom: 40%;
}

.spaceGameContent .prompt {
  background-color: #1b1614;
  width: 450px;
  height: 450px;
  display: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
}

.spaceGameContent .instruction {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.spaceGameContent .instructionPrompt {
  z-index: 2;
  border: 1px solid;
  display: flex;
  gap: 1em;
  flex-direction: column;
  position: absolute;
  width: 35%;
  height: min-content;
  padding: 20px;
  backdrop-filter: blur(30px);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.spaceGameContent .instructionPrompt p {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.spaceGameContent .instructionPrompt span {
  color: red;
  font-weight: bold;
}

.spaceGameContent .instructionPrompt button {
  color: white;
  width: 100px;
  border-radius: 10px;
  background-color: rgb(28, 28, 28);
}

.spaceGameContent #fuelInstruction {
  margin: 0;
  color: limegreen;
}

.spaceGameContent #mInstruction {
  width: 80%;
  display: none;
}

.spaceGameContent #lostInSpace {
  background-image: url('./lostInSpace.png');
  /* display: flex; */
  background-size: contain;
}

.spaceGameContent #gameOver {
  background-image: url('./hitByAsteroid.png');
  /* display: flex; */
  background-size: contain;
}

.spaceGameContent #lowFuel {
  background-image: url('./lowFuel.png');
  /* display: flex; */
  background-size: contain;
}

.spaceGameContent #won {
  background-image: url('./won.png');
  /* display: flex; */
  background-size: contain;
}

@media screen and (max-width: 1360px) {
  .spaceGameContent .buttonsSpace {
    top: 36vh;
  }
}

@media screen and (max-width: 1024px) {
  .spaceGameContent .titleSpace {
    background-size: 140%;
  }
}

@media screen and (max-width: 500px) {
    
  .spaceGameContent .prompt {
    width: 350px;
    height: 350px;
  }

  .spaceGameContent .buttonsSpace {
    top: 30vh;
  }

  .spaceGameContent #dInstruction {
    display: none;
  }

  .spaceGameContent #mInstruction {
    display: flex;
  }

  .spaceGameContent .titleSpace {
    background-size: 140% auto;
  }
}

@media screen and (max-width: 376px) {
  .spaceGameContent .prompt {
    width: 250px;
    height: 250px;
  }

  .spaceGameContent .buttonsSpace button {
    width: 109px;
  }

  .spaceGameContent .buttonsSpace {
    top: 22vh;
  }

  .spaceGameContent .gameOver div p {
    font-size: unset;
  }
}