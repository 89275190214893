.evolution-sci .prompt {
  padding: 5%;
  color: white;
  position: relative;
  display: flex;
  z-index: 7;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.evolution-sci .portal-slider-container,
.evolution-sci .teleporter-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.evolution-sci .portal-slider-container {
  overflow: hidden;
  z-index: 0;
}

.evolution-sci .teleporter-container {
  bottom: 0;
  height: 80%;
  justify-content: center;
  align-items: center;
  z-index: 2;
  pointer-events: none;
}

.evolution-sci .teleporter-image,
.evolution-sci .teleporter-image2 {
  position: absolute;
  height: 100%;
  object-fit: contain;
}

.evolution-sci .teleporter-image2 {
  opacity: 0;
  transform: scale(0);
}

.evolution-sci .portal-image {
  object-fit: cover;
  width: 100%;
}

.evolution-sci .next-portal-image-mask {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.evolution-sci .slide-content {
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.evolution-sci .ripple {
  opacity: 0;
}

/*  Text Content CSS  */
.evolution-sci .text-container-parent1,
.evolution-sci .text-container-parent2 {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.evolution-sci .text-container-parent2 {
  opacity: 0;
}

.evolution-sci .text-container {
  display: flex;
  justify-content: space-between;
  width: 60%;
  height: 100%;
}

.evolution-sci .text-container .left-part,
.evolution-sci .text-container .right-part {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.evolution-sci .text-container .left-part {
  align-items: flex-start;
  gap: 1vw;
}

.evolution-sci .text-container .right-part {
  align-items: flex-end;
}

.evolution-sci .text-container .left-part .caption {
  font-size: 0.6vw;
  letter-spacing: 0.5vh;
  color: bisque;
}

.evolution-sci .text-container .left-part .head-container {
  width: 80%;
}

.evolution-sci .text-container .left-part .heading {
  font-size: 3.2vw;
  font-weight: 800;
  color: white;
  text-align: start;
  line-height: 3vw;
}

.evolution-sci .text-container .info {
  font-size: 0.8vw;
  color: white;
  width: 55%;
  text-align: start;
}

.evolution-sci .text-container .caption,
.evolution-sci .text-container .heading,
.evolution-sci .text-container .info {
  text-shadow: 2px 2px 4px black;
}

.evolution-sci .rectangle1,
.evolution-sci .rectangle2 {
  position: absolute;
  transform-origin: center center;
}

.evolution-sci .instruction {
  position: absolute;
  width: 100%;
  bottom: 2%;
  color: white;
  text-align: center;
  font-size: 0.8vw;
  z-index: 4;
}

.evolution-sci .fade-out {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.evolution-sci .rapid-click-warning {
  font-size: 1.5vh;
  position: fixed;
  top: -100px; /* start off the screen */
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336;
  background-size: cover;
  color: white;
  padding: 10px 20px;
  border-radius: 0 0 5px 5px;
  z-index: 1000;
  transition: top 0.5s ease-in-out; /* animate the top property */
}

.evolution-sci .rapid-click-warning.show {
  top: 2%;
}

@media only screen and (max-width: 450px) {
  .evolution-sci .text-container {
    width: 100%;
    height: 90%;
    flex-direction: column;
  }

  .evolution-sci .text-container .right-part,
  .evolution-sci .text-container .left-part {
    align-items: center;
  }

  .evolution-sci .text-container .right-part {
    padding-top: 10%;
  }

  .evolution-sci .text-container .right-part {
    justify-content: flex-end;
  }

  .evolution-sci .text-container .left-part .caption {
    font-size: 1.2vh;
    letter-spacing: 1vh;
  }

  .evolution-sci .text-container .left-part .heading {
    font-size: 5vh;
    line-height: 5vh;
    text-align: center;
  }

  .evolution-sci .text-container .info {
    /* position: fixed;
    height: 100%; */
    width: 75%;
    text-align: justify;
    font-size: 1.8vh;
    /* top: 87vh; */
  }

  .evolution-sci .instruction {
    font-size: 1.2vh;
  }
}
