/* Gallery Styling */

.introGallery .nav .backButton,
.tactileGallery .nav .backButton,
.illusionGallery .nav .backButton,
.digitalGallery .nav .backButton,
.kineticGallery .nav .backButton,
.cardboardGallery .nav .backButton {
  position: absolute;
  top: 125%;
  left: 4.5%;
}

.introGallery .nav .backButton span,
.tactileGallery .nav .backButton span,
.illusionGallery .nav .backButton span,
.digitalGallery .nav .backButton span,
.kineticGallery .nav .backButton span,
.cardboardGallery .nav .backButton span {
  padding-left: 10%;
  letter-spacing: 0.12vw;
}

.introGallery .nav,
.tactileGallery .nav,
.illusionGallery .nav,
.digitalGallery .nav,
.cardboardGallery .nav,
.kineticGallery .nav {
  background-color: #eeeff1;
  box-shadow: 0px 3px 50px #00000014;
  position: absolute;
}

.introGallery .nav #logo,
.tactileGallery .nav #logo,
.illusionGallery .nav #logo,
.digitalGallery .nav #logo,
.cardboardGallery .nav #logo,
.kineticGallery .nav #logo {
  background-image: url(/public/logo-black.png);
}

.introGallery .introContent,
.tactileGallery .tactileContent,
.illusionGallery .illusionContent,
.digitalGallery .digitalContent,
.cardboardGallery .cardboardContent,
.kineticGallery .kineticContent {
  background-color: #eeeff1;
}

.introGallery .introContent h1,
.introGallery .introContent p,
.tactileGallery .tactileContent h1,
.tactileGallery .tactileContent p,
.illusionGallery .illusionContent h1,
.illusionGallery .illusionContent p,
.cardboardGallery .cardboardContent h1,
.cardboardGallery .cardboardContent p,
.digitalGallery .digitalContent h1,
.digitalGallery .digitalContent p,
.kineticGallery .kineticContent h1,
.kineticGallery .kineticContent p {
  color: #000;
}

.introGallery .introContent .subContent1,
.tactileGallery .tactileContent .subContent1,
.illusionGallery .illusionContent .subContent1,
.digitalGallery .digitalContent .subContent1,
.cardboardGallery .cardboardContent .subContent1,
.kineticGallery .kineticContent .subContent1 {
  display: flex;
  padding-top: 6%;
  gap: 3%;
}

/* Gallery Styling */

.incity {
  /* display: flex;
  justify-content: center; */
  background-color: #0b0c10;
}

.incity .ticketAlert {
  font: normal normal normal 1.04vw / 1.6vw Helvetica Neue;
  letter-spacing: 0.05vw;
  color: #ffffff;
  text-shadow: 3px 3px 50px #00000040;
  position: fixed;
  background: #d7326e 0% 0% no-repeat padding-box;
  top: 14vh;
  z-index: 1005;
  width: 100%;
  text-align: center;
  padding: 1%;
}

.incity .ticketAlert img {
  height: 20%;
  position: absolute;
  right: 4%;
  top: 38%;
}

.incity .ticketAlert img:hover {
  cursor: pointer;
}

.incity .incityContent {
  display: flex;
  padding-top: 8%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
}

.incity .incityContent .incityCarousel {
  height: 80vh;
  width: 93%;
  position: relative;
  /* For Placeholder purpose 
   background-image: url(/public/placeHolders/PARSEC/placeholder.jpg);
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
    url(https://digitalassestbucket.s3.amazonaws.com/IncityGallery/parsec.webp); */
  background-size: cover;
  background-position: center;
  /* For Placeholder purpose */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
}
.incity .incityContent .incityCarousel .carouselList {
  width: 100%;
  display: inline-block;
  background-size: cover; /* If you want the image to cover the entire div */
  background-position: center; /* Center the background image */
}

.incity .incityContent .incityCarousel .video-overlay {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
}

.incity .incityContent .incityCarousel iframe {
  position: absolute;
  height: 100vh;
  width: 100vw;
  scale: 1.12;
  pointer-events: none;
  z-index: 0;
}

.incity .incityContent .incityCarousel .subContent1 {
  width: 80%;
  display: flex;
}

.incity .incityContent .incityContent90 {
  width: 90%;
  /* padding-bottom: 10%; */
  display: FLEX;
  flex-direction: column;
  align-items: center;
}

.incity .incityContent .incityContent90 .incityGalleryContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: rgb(255, 255, 255);
  margin-bottom: 2%;
}

.incity .incityContent .incityContent90 .incityGalleryContent h1 {
  font-size: 5.2vw;
  margin: 5% 0 0 0;
  width: 70%;
  text-align: center;
  letter-spacing: 0.26vw;
  font-weight: bold;
}

.incity .incityContent .incityContent90 .incityGalleryContent p {
  width: 55%;
  text-align: center;
  font-size: 1.04vw;
  line-height: 1.9vw;
  letter-spacing: 0.06vw;
  color: #ffffff;
}

.incity .incityContent .incityContent90 h2 {
  font-weight: bold;
  color: #555558;
  font-size: 2.08vw;
  line-height: 4.4vw;
  letter-spacing: 0.24vw;
  /* margin: 5% 0; */
}

.incity .incityContent .incityContent90 .highlights {
  width: 100%;
  margin-bottom: 10%;
  /* display: none; */
}

.incity .incityContent .incityContent90 .highlights h2 {
  text-align: center;
}

.incity .incityContent .incityContent90 .highlights .highlightsContent {
  display: flex;
  justify-content: center;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer {
  border: 1px solid #0b0c10;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 25px;
  width: 80%;
  height: 55vh;
  background-size: cover;
  padding: 2%;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .subBannerContainer {
  position: absolute;
  background-size: cover;
  border-radius: 25px;
  height: 56vh;
  width: 96%;
  padding: 2%;
  top: 0;
  left: 0;
  /* z-index: -1;
  opacity: 0; */
  transition: opacity 0.8s ease-out; /* Transition effect for dissolving */
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 23%;
  gap: 3%;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .startNEndDate {
  display: flex;
  align-items: center;
  border-radius: 30px;
  backdrop-filter: blur(150px);
  padding: 15% 3%;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .startNEndDate
  hr {
  height: 100%;
  border: 2px solid;
  border-radius: 25px;
  opacity: 0.5;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .highlightIndicators {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 2%;
  left: 0;
  width: 100%;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .highlightIndicators
  .indicatorContainer {
  width: 7vw;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .highlightIndicators
  .indicatorContainer
  ul {
  list-style: none; /* Removes default bullets */
  display: flex;
  justify-content: space-around;
  padding: 0;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .highlightIndicators
  .indicatorContainer
  ul
  li {
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  width: 0.7vw; /* Set your default width */
  height: 0.7vw; /* Adjust as needed */
  cursor: pointer;
  opacity: 0.5;
  transition: 0.8s ease-in-out;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .highlightIndicators
  .indicatorContainer
  ul
  li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0.7vw;
  height: 0.7vw;
  border-radius: 50px;
  background-color: rgb(255, 255, 255); /* Bullet color */
  transition: width 0.8s ease-in-out;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .highlightIndicators
  .indicatorContainer
  ul
  li.active::before {
  width: 3.5vw; /* Increased width for active bullet */
  background-color: rgb(
    255,
    255,
    255
  ); /* Change color for active state if desired */
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .highlightIndicators
  .indicatorContainer
  ul
  li.active {
  opacity: 1;
  width: 3.5vw;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .date {
  width: fit-content;
  /* height: 18vh; */
  /* padding: 15% 27%; */
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .date
  p {
  text-align: center;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .date
  .startEndLabel,
.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .date
  .month
  p {
  font: normal normal normal 1.1vw/2vw Helvetica Neue;
  letter-spacing: 0.07vw;
  opacity: 0.5;
  margin: 0;
  height: 3.5vh;
  font-weight: bold;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  div {
  width: auto;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .date
  .datenDay {
  display: flex;
  width: max-content;
  justify-content: center;
  height: 40%;
  overflow: hidden;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .date
  .datenDay
  h1 {
  margin: 0;
  font-size: 6vh;
  text-align: center;
  height: 8vh;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .time
  h1 {
  margin: 0;
  font-size: 2vh;
  text-align: center;
  height: 2.6vh;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .time
  #am-pm
  h1 {
  margin: 0;
  height: 2.6vh;
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  font: normal normal normal 1.1vw vw / 2vw Helvetica Neue;
  letter-spacing: 0.07vw;
  opacity: 0.5;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .date
  #dateOnes,
.incity .incityContent .incityContent90 .highlights .date #dateTens {
  height: 7.9vh;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .date
  #dateOnes,
.incity .incityContent .incityContent90 .highlights .date #dateTens,
.incity .incityContent .incityContent90 .highlights .date .month div,
.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .time
  div {
  transition: 0.8s ease-in-out;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .bannerContainer
  .dateTimeAndNavigation
  .time {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* height: 12vw; */
  backdrop-filter: blur(150px);
  border-radius: 30px;
  padding: 5% 0;
  overflow: hidden;
  gap: 3%;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .bannerContainer
  .dateTimeAndNavigation
  .time
  h2 {
  opacity: 0.5;
  color: white;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .bannerContainer
  .dateTimeAndNavigation
  .time
  .timeContainer {
  width: 32%;
  /* gap: 0 !important; */
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .bannerContainer
  .dateTimeAndNavigation
  .time
  div {
  height: 2.6vh;
  /* overflow: hidden; */
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .navigation {
  width: 100%;
  display: flex;
  gap: 5%;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .navigation
  div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(150px);
  height: 10vh;
  border-radius: 30px;
  transition: 0.5s ease;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .dateTimeAndNavigation
  .navigation
  .hoverEffect:hover {
  cursor: pointer;
  background-color: #d1d1d00c;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  div {
  width: 50%;
  z-index: 1;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .titleDescriptionAndBookButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 75%;
  height: 90%;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .titleDescriptionAndBookButton
  h2 {
  text-align: left;
  font: normal normal bold 2.09vw/2.6vw Helvetica Neue;
  letter-spacing: 0.1vw;
  color: white;
}
.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .titleDescriptionAndBookButton
  p,
.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .titleDescriptionAndBookButton
  .bookButton {
  font: normal normal normal 1.4vw/2vw Helvetica Neue;
  letter-spacing: 0.07vw;
  margin: 0;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .titleDescriptionAndBookButton
  p
  span:hover {
  cursor: pointer;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .titleDescriptionAndBookButton
  .bookButton {
  background-color: #d7326e;
  border-radius: 50px;
  padding: 2% 4%;
  width: fit-content;
  margin: 10% 0 0;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .titleDescriptionAndBookButton
  .bookButton:hover {
  cursor: pointer;
}

.incity .incityContent .incityContentHead {
  /* padding-top: 10%; */
  /* position: absolute; */
  /* bottom: 10%; */
  height: min-content;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  /* text-shadow: 4px 4px 30px #000000; */
}

.incity .incityContent .incityContentHead .incityContentHeadTitle {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.incity .incityContent .incityContentHead .incityContentHeadTitle hr {
  width: 25%;
}

.incity .incityContent .incityContentHead .incityContentHeadTitle .parsec {
  font-size: 10.5vw;
  letter-spacing: 2.1vh;
  line-height: 8.6vw;
}

.incity .incityContent .incityContentHead .incityContentHeadTitle .jayanagar {
  font-size: 3vw;
}

.incity .incityContent .incityContentHead .incityContentPara {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.incity .incityContent .incityContentHead div {
  /* width: 50%; */
}

.incity .incityContent .incityContentHead div h1,
.incity .incityContent .incityContentHead .timer {
  font-size: 5.2vw;
  letter-spacing: 0.26vw;
  line-height: 6vw;
  font-weight: bold;
  margin: 0;
}

.incity .incityContent .incityContentHead .incityContentPara .timerLabels {
  width: 120%;
  display: flex;
  justify-content: space-around;
}

.incity .incityContent .incityContentHead .incityContentPara .timerLabels div {
  width: 33%;
  display: flex;
  justify-content: center;
}

.incity .incityContent .incityContentHead .incityContentPara .timerLabels p {
  margin: 0;
}

.incity .incityContent .incityContentHead p,
.incity .incityContent .cardSlots .cardSlot .cardDisplay .buttonContainer {
  font-size: 1.04vw;
  line-height: 1.6vw;
  letter-spacing: 0.26vw;
}

.incity .incityContent .incityContentHead .incityContentHeadTitle p {
  margin: 0;
}

.incity .incityContent .cardSlots {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 10%;
  gap: 2rem;
}

.incity .incityContent .cardSlots .cardSlot {
  width: 25.5vw;
  height: max-content;
  padding-bottom: 4%;
}

.incity .incityContent .cardSlots .cardSlot a {
  text-decoration: none;
  color: white;
}

.incity
  .incityContent
  .cardSlots
  .cardSlot
  a
  .buttonContainer
  .buttonText::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 0.23vh;
  bottom: 10%;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.incity .incityContent .cardSlots .cardSlot a:hover .buttonText::before {
  transform: scaleX(0.1);
}

.incity .incityContent .cardSlots .cardSlot .cardDisplay {
  height: 35vh;
  width: 100%;
  display: flex;
  background-size: 100% 100%;
  align-items: flex-end;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 3px 50px #00000014;
}

.incity .incityContent .cardSlots .cardSlot .buttonContainer {
  height: 30% !important;
  display: flex;
  gap: 6.6%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  /* backdrop-filter: blur(8px); */
  /* backdrop-filter: blur(25px); */
  overflow: hidden;
  position: relative;
  transition: height 0.5s ease; /* Adjust the time and easing function as needed */
  border-radius: 0 0 20px 20px;
  padding-top: 6%;
}

.incity .incityContent .cardSlots .cardSlot .cardDisplay a {
  position: absolute;
  top: 22%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

/* .incity .incityContent .cardSlots .cardSlot .cardDisplay .redirectIcon {
  background-image: url(https://digitalassestbucket.s3.amazonaws.com/IncityGallery/Icons/RedirectIcon.svg);
  height: 5.2vw;
  width: 5.2vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
} */

.incity .incityContent .buttonContainer .buttonText {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.04vw;
  line-height: 1.3vw;
  letter-spacing: 0.06vw;
  color: black;
}

.incity
  .incityContent
  .cardSlots
  .cardSlot
  .cardDisplay:hover
  .buttonContainer {
  height: 100% !important;
}

.incity
  .incityContent
  .cardSlots
  .cardSlot
  .cardDisplay:hover
  .buttonContainer
  .buttonText {
  height: 80% !important;
}

.incity .incityContent .cardSlots .cardSlot .gallaryDescription {
  text-align: center;
  font-size: 1.04vw;
  line-height: 1.3vw;
  letter-spacing: 0.06vw;
  color: #777778;
}

.incity .incityContent .cardSlots .cardSlot .cardDisplay:hover .redirectIcon {
  opacity: 1;
  visibility: visible;
}

.incity .footer .footerMainContent .details .linksAndContact .redirects {
  display: inline;
}

.incity
  .incityContent
  .incityContent90
  .highlights
  .highlightsContent
  .bannerContainer
  .titleDescriptionAndBookButton
  p {
  opacity: 0.5;
}

/*------------------------ MOBILE VIEW ------------------------*/

@media only screen and (max-width: 450px) {
  /* Gallery Nav Styling */
  .introGallery .nav .backButton,
  .tactileGallery .nav .backButton,
  .illusionGallery .nav .backButton,
  .digitalGallery .nav .backButton,
  .kineticGallery .nav .backButton,
  .cardboardGallery .nav .backButton {
    position: absolute;
    top: 125%;
    left: 10%;
  }
  /* Gallery Nav Styling */

  .incity .ticketAlert {
    font: normal normal normal 1.9vh / 2.5vh Helvetica Neue;
    letter-spacing: 0.02vh;
    top: 7.9vh;
    width: 88%;
    padding: 2% 6%;
  }

  .incity .ticketAlert img {
    right: 3%;
  }

  .incity .incityContent {
    width: 100%;
    padding-top: 18%;
    /* padding-bottom: 90%; */
  }

  .incity .incityContent .incityCarousel {
    height: 84vh;
  }

  .incity .incityContent .incityContentHead .incityContentHeadTitle .jayanagar {
    font-size: 3vh;
  }

  .incity .incityContent .incityContent90 {
    width: 80%;
  }

  .incity .incityContent .incityContent90 h2 {
    font-size: 1.56vh;
    line-height: 2.4vh;
    letter-spacing: 0.24vh;
  }

  .incity .incityContent .incityContent90 .incityGalleryContent {
    margin: 10% 0;
  }

  .incity .incityContent .incityContent90 .incityGalleryContent h1,
  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .titleDescriptionAndBookButton
    h2 {
    text-align: center;
    margin: 5% 0 0 0;
    width: 100%;
    font: normal normal bold 1.85vh/2.3vh Helvetica Neue;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .titleDescriptionAndBookButton
    h2 {
    text-align: left;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .date
    .datenDay
    h1 {
    text-align: center;
    font: normal normal bold 5vh/5vh Helvetica Neue;
    height: 5vh;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .bannerContainer
    .dateTimeAndNavigation
    .time
    div {
    height: 2vh;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .time
    h1 {
    text-align: center;
    font: normal normal bold 2vh / 2vh Helvetica Neue;
    height: 2vh;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .date
    .datenDay {
    /* height: 40%; */
    height: 5vh;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .date
    .startEndLabel {
    height: auto;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .date
    .startEndLabel,
  .incity .incityContent .incityContent90 .incityGalleryContent p,
  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .titleDescriptionAndBookButton
    p,
  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .date
    .month
    p,
  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .time
    #am-pm
    p {
    width: 100%;
    letter-spacing: 0.08vh;
    font: normal normal normal 1.6vh/2.4vh Helvetica Neue;
    /* opacity: 0.5; */
  }

  /* .incity
    .incityContent
    .incityContent90
    .highlights
    .bannerContainer
    .dateTimeAndNavigation
    .time
    div {
    height: 2vh;
  } */

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .titleDescriptionAndBookButton
    .bookButton {
    letter-spacing: 0.08vh;
    font: normal normal normal 1.6vh/2.4vh Helvetica Neue;
  }

  .incity .incityContent .incityCarousel .slick-slider .slick-dots {
    width: 100%;
    transform: none;
    display: flex;
    justify-content: center;
    bottom: 8%;
  }

  .slick-dots li button:before {
    font-size: 1.3vh !important;
  }

  .incity .incityContent .incityContentHead {
    flex-direction: column;
    top: 0;
    justify-content: center;
  }

  .incity .incityContent .incityContentHead .incityContentPara {
    width: 100%;
  }

  .incity .incityContent .incityContentHead .incityContentPara .bookTicket,
  .incity
    .incityContent
    .incityContentHead
    .incityContentHeadTitle
    .bookTicket {
    width: auto;
    background-color: #d7326e;
    padding: 4% 8%;
    border-radius: 20px;
    letter-spacing: 0.08vh;
    text-transform: uppercase;
  }

  .incity .incityContent .incityContentHead .incityContentHeadTitle {
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .incity .incityContent .incityContentHead .incityContentHeadTitle hr {
    width: 35%;
  }

  .incity .incityContent .incityContentHead p,
  .incity .incityContent .incityContentHead .incityContentHeadTitle p {
    width: 70%;
  }
  .incity .incityContent .incityContentHead .incityContentPara p {
    padding-bottom: 10%;
  }

  .incity .incityContent .incityContentHead .incityContentHeadTitle p {
    /* padding-top: 5%; */
    font-size: 1.4vh;
  }

  .incity .incityContent .incityContentHead .incityContentPara .timerLabels p {
    padding-bottom: 2%;
  }

  .incity .incityContent .incityContentHead div,
  .incity .incityContent .incityContentHead .incityContentPara .comingSoon p {
    width: 100%;
  }

  .incity .incityContent .incityContentHead .incityContentPara .comingSoon p {
    color: #d1d1d0;
    font-weight: 600;
  }

  .incity .incityContent .incityContentHead .incityContentHeadTitle .parsec {
    font: normal normal bold 5.7vh/7vh Helvetica Neue;
    letter-spacing: 0.3vh;
  }

  .incity .incityContent .incityContentHead div h1,
  .incity .incityContent .incityContentHead div h1,
  .incity .incityContent .incityContentHead .timer {
    font-size: 5.2vh;
    line-height: 6.5vh;
    letter-spacing: 0.36vh;
  }

  .incity .incityContent .incityContentHead .incityContentPara .timerLabels {
    width: 76%;
  }

  .incity .incityContent .cardSlots .cardSlot {
    width: 100%;
  }

  .incity .incityContent .cardSlots .cardSlot .cardDisplay {
    height: 24vh;
  }

  .incity .incityContent .cardSlots .cardSlot .cardDisplay a {
    height: 100%;
    top: 0;
  }

  .incity .incityContent .cardSlots .cardSlot .cardDisplay .redirectIcon {
    height: 100%;
    width: 100%;
  }

  .incity .incityContent .incityContentHead p,
  .incity .incityContent .cardSlots .cardSlot .cardDisplay .buttonContainer {
    font-size: 1.66vh;
    line-height: 2.7vh;
    letter-spacing: 0.08vh;
    margin: 0;
  }

  .incity .incityContent .buttonContainer .buttonText,
  .incity .incityContent .cardSlots .cardSlot .gallaryDescription {
    font-size: 1.67vh;
    line-height: 2vh;
    letter-spacing: 0.09vh;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer {
    justify-content: flex-start;
  }

  .incity .incityContent .incityContent90 .bannerContainer {
    flex-direction: column-reverse;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .titleDescriptionAndBookButton {
    justify-content: flex-start;
    height: auto !important;
    padding: 0 2%;
    margin-bottom: 40%;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .titleDescriptionAndBookButton
    p {
    padding-top: 4%;
  }

  .incity .incityContent .incityContent90 .highlights {
    margin-bottom: 30%;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer,
  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .subBannerContainer {
    width: 95%;
    height: 70vh;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation {
    /* flex-direction: row; */
    justify-content: space-between;
    height: auto !important;
    width: 100%;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .startNEndDate {
    justify-content: space-evenly;
    width: 90%;
    padding: 6% 3%;
    border-radius: 20px;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .startNEndDate
    hr {
    margin: 0 6%;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .titleDescriptionAndBookButton {
    width: 96%;
    height: 30%;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation {
    align-items: center;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .date {
    height: fit-content;
    padding: 7%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5vh;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .date
    #dateOnes,
  .incity .incityContent .incityContent90 .highlights .date #dateTens {
    height: 5vh;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .bannerContainer
    .dateTimeAndNavigation
    .time {
    height: fit-content;
    width: 82% !important;
    text-align: center;
    padding: 5% 7%;
    border-radius: 20px;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .dateTimeAndNavigation
    .time
    #am-pm
    p {
    height: 2vh;
    width: max-content;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .highlightIndicators
    .indicatorContainer {
    width: 15vw;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .bannerContainer
    .highlightIndicators
    .indicatorContainer
    ul
    li.active {
    width: 3.5vh;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .highlightIndicators
    .indicatorContainer
    ul
    li::before {
    width: 0.7vh;
    height: 0.7vh;
  }

  .incity
    .incityContent
    .incityContent90
    .highlights
    .highlightsContent
    .highlightIndicators
    .indicatorContainer
    ul
    li.active::before {
    width: 3.5vh;
  }
}
