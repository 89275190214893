.carouselSlider {
  position: relative;
  padding-bottom: 10%;
}

.carouselSlider .slick-slider {
  width: 100vw;
  /* padding-bottom: 5%; */
  display: flex !important;
  justify-content: center;
}

.carouselSlider .left,
.carouselSlider .right {
  position: absolute;
  height: 100%;
  width: 10%;
  z-index: 1;
}

.carouselSlider .left {
  background: transparent linear-gradient(90deg, #0b0c10 0%, #0b0c1000 100%) 0%
    0% no-repeat padding-box;
}

.carouselSlider .right {
  background: transparent linear-gradient(270deg, #0b0c10 0%, #0b0c1000 100%) 0%
    0% no-repeat padding-box;
}

.carouselSlider .slick-slider .slick-track {
  display: flex;
  gap: 0.2%;
}

.carouselSlider .slick-slider .slick-track .slick-slide {
  /* width: 35vw !important; */
}

.carouselSlider .slick-slider .slick-track .carousel-slide img {
  border-radius: 20px;
}

.carouselSlider .slick-slider .slick-slide div {
  width: 100%;
}

.carouselSlider .slick-slider .slick-slide a {
  text-decoration: none;
}

.carouselSlider .slick-slider .slick-arrow {
  display: none !important;
}

.carouselSlider .slick-slider .slick-dots {
  bottom: -80px;
  width: 15%;
}

.carouselSlider .slick-slider .slick-dots li {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  opacity: 0.5;
  height: 0.7vw;
  width: 0.7vw;
  transition: 0.5s ease-in-out;
}

.carouselSlider .slick-slider .slick-dots li button:before {
  display: none;
}

.carouselSlider .slick-slider .slick-dots .slick-active {
  opacity: 1;
  width: 3.5vw;
}

.carouselSlider .carousel-slide {
  position: relative;
}

.carouselSlider .carousel-slide .darkOverlay {
  /* Add styles for the slide */
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent linear-gradient(180deg, #0b0c1000 0%, #0b0c10 100%) 0%
    0% no-repeat padding-box;
}

.carouselSlider .carousel-slide .slideDescription {
  position: relative;
  z-index: 1;
}

.carouselSlider .carousel-slide .slideDescription h2 {
  font-size: 1.56vw;
  line-height: 2.1vw;
  letter-spacing: 0.09vw;
  font-weight: bold;
  color: white;
}

.carouselSlider .carousel-slide .slideDescription p {
  font-size: 1.04vw;
  line-height: 1.56vw;
  letter-spacing: 0.05vw;
  opacity: 0.5;
}

.carouselSlider .carousel-slide img {
  /* Style for images */
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.carouselSlider .carousel-slide button {
  /* Style for buttons */
}

/* background-image: linear-gradient(to right,rgba(0,0,0,1),rgba(0,0,0,0.8),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1));
height: 32vh;
position: absolute;
width: 100%;
z-index: 1; */

@media only screen and (max-width: 450px) {
  .carouselSlider {
    padding-bottom: 30%;
  }

  .carouselSlider .slick-slider .slick-track {
    gap: 0.5%;
  }

  .carouselSlider .carousel-slide img {
    /* Style for images */
    height: 25vh;
  }

  .carouselSlider .carousel-slide .slideDescription h2 {
    font: normal normal bold 1.8vh/2.3vh Helvetica Neue;
    letter-spacing: 0, 09vh;
  }

  .carouselSlider .carousel-slide .slideDescription p {
    font: normal normal normal 1.6vh/2.4vh Helvetica Neue;
    letter-spacing: 0.08vh;
  }

  .carouselSlider .slick-slider .slick-dots {
    width: 30%;
    bottom: -10%;
  }

  .carouselSlider .slick-slider .slick-dots li {
    height: 0.7vh;
    width: 0.7vh;
  }

  .carouselSlider .slick-slider .slick-dots .slick-active {
    width: 3.5vh;
  }
}
