.faqForParsec {
  color: white;
  display: grid;
  place-items: center;
  padding-bottom: 5%;
}

.faqForParsec .faqForParsecContent {
  width: 55%;
}

.faqForParsec .faqForParsecContent hr {
  width: 100%;
  margin: 0;
}

.faqForParsec .faqForParsecContent h2 {
  font: normal normal bold 1.55vw / 3.1vw Helvetica Neue;
  letter-spacing: 0.23vw;
  color: #626264;
  text-align: center;
  font-weight: bold;
}

.faqForParsec .faqForParsecContent .faqs {
  position: relative;
  overflow: hidden;
  transition: 0.3s ease;
}

.faqForParsec .faqForParsecContent .faqs p,
.faqForParsec .faqForParsecContent .seeMoreFAQs {
  width: 94%;
  font: normal normal normal 1.04vw / 1.6vw Helvetica Neue;
  letter-spacing: 0.05vw;
  padding: 3%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  transition: 0.3s ease;
}

.faqForParsec .faqForParsecContent .faqs p.faqAnswer {
  padding: 0 3%;
  width: 90%;
}

.faqForParsec .faqForParsecContent .faqs img {
  transition: 0.3s ease;
  position: absolute;
  top: 4vh;
  right: 3%;
}

.faqForParsec .faqForParsecContent .faqs hr {
  margin: 0;
}

.faqForParsec .faqForParsecContent .faqs img.expanded {
  transform: rotate(90deg); /* Rotate arrow */
}

.faqForParsec .faqForParsecContent .faqs.show {
  height: 18vh;
  /* height: max-content; */
}

.faqForParsec .faqForParsecContent .faqs.hide {
  height: 9vh;
}

.faqForParsec .faqForParsecContent .seeMoreFAQs {
  justify-content: center;
}

.faqForParsec .faqForParsecContent .seeMoreFAQs .seeMoreButton {
  border-radius: 30px;
  border: 2px solid white;

  padding: 1% 7%;
  width: max-content;
}

.faqForParsec .faqForParsecContent .faqs img:hover,
.faqForParsec .faqForParsecContent .seeMoreFAQs .seeMoreButton:hover {
  cursor: pointer;
}

.faqForParsec .faqForParsecContent a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 450px) {
  .faqForParsec {
    padding-bottom: 10%;
  }

  .faqForParsec .faqForParsecContent {
    width: 85%;
  }

  .faqForParsec .faqForParsecContent .seeMoreFAQs {
    padding-top: 5%;
  }

  .faqForParsec .faqForParsecContent h2,
  .faqForParsec .faqForParsecContent .faqs p,
  .faqForParsec .faqForParsecContent .seeMoreFAQs {
    font: 1.9vh / 2.5vh Helvetica Neue;
    letter-spacing: 0.09vh;
    /* font-weight: bold; */
  }

  .faqForParsec .faqForParsecContent .faqs.hide {
    height: 5vh;
  }

  .faqForParsec .faqForParsecContent .faqs.show {
    height: 16vh;
    /* height: max-content; */
  }

  .faqForParsec .faqForParsecContent .faqs img{
    top: 1.5vh;
  }
}
