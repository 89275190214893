.paramLoading {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 2%;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.paramLoading img {
  z-index: 1;
  position: relative;
  object-fit: cover;
  height: 10%;
}

.paramLoading p {
  font-size: 0.8vw;
  letter-spacing: 1vw;
  font-weight: bold;
}

@keyframes rotate-and-stop {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotate 1s linear infinite;
}

.slowing-down {
  animation: rotate-and-stop 2s ease-out forwards;
}

.hidden {
  display: none;
}

@keyframes rotate-smooth {
  0% {
    transform: rotate(0deg);
  }
  /* 25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  } */
  100% {
    transform: rotate(360deg);
  }
}

.rotating-smooth {
  animation: rotate-smooth 3s ease-in-out infinite;
}

@media only screen and (max-width: 450px) {
  .paramLoading p {
    font-size: 0.8vh;
    letter-spacing: 1vh;
    font-weight: bold;
  }
}
