/* {
  /* color: white; */
  /* height: auto; */
/*}*/

body {
  margin: 0;
  /* background-color: #000000; */
}

#loader{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #090707;
  z-index: 2;
}

#fireBox {  
  font-family: 'VT323', monospace;
}

.dank-ass-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.dank-ass-loader .row {
  display: flex;
}

.arrow {
  width: 0;
  height: 0;
  margin: 0 -6px !important;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 21.6px solid #fd7000;
  -webkit-animation: blink 1s infinite;
  animation: blink 1s infinite;
  filter: drop-shadow(0 0 18px #fd7000);
}

.arrow.down {
  transform: rotate(180deg);
}

.up {
  transform: translateX(0%) !important;
}

.arrow.outer-1 {
  -webkit-animation-delay: -0.0555555556s;
  animation-delay: -0.0555555556s;
}

.arrow.outer-2 {
  -webkit-animation-delay: -0.1111111111s;
  animation-delay: -0.1111111111s;
}

.arrow.outer-3 {
  -webkit-animation-delay: -0.1666666667s;
  animation-delay: -0.1666666667s;
}

.arrow.outer-4 {
  -webkit-animation-delay: -0.2222222222s;
  animation-delay: -0.2222222222s;
}

.arrow.outer-5 {
  -webkit-animation-delay: -0.2777777778s;
  animation-delay: -0.2777777778s;
}

.arrow.outer-6 {
  -webkit-animation-delay: -0.3333333333s;
  animation-delay: -0.3333333333s;
}

.arrow.outer-7 {
  -webkit-animation-delay: -0.3888888889s;
  animation-delay: -0.3888888889s;
}

.arrow.outer-8 {
  -webkit-animation-delay: -0.4444444444s;
  animation-delay: -0.4444444444s;
}

.arrow.outer-9 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.arrow.outer-10 {
  -webkit-animation-delay: -0.5555555556s;
  animation-delay: -0.5555555556s;
}

.arrow.outer-11 {
  -webkit-animation-delay: -0.6111111111s;
  animation-delay: -0.6111111111s;
}

.arrow.outer-12 {
  -webkit-animation-delay: -0.6666666667s;
  animation-delay: -0.6666666667s;
}

.arrow.outer-13 {
  -webkit-animation-delay: -0.7222222222s;
  animation-delay: -0.7222222222s;
}

.arrow.outer-14 {
  -webkit-animation-delay: -0.7777777778s;
  animation-delay: -0.7777777778s;
}

.arrow.outer-15 {
  -webkit-animation-delay: -0.8333333333s;
  animation-delay: -0.8333333333s;
}

.arrow.outer-16 {
  -webkit-animation-delay: -0.8888888889s;
  animation-delay: -0.8888888889s;
}

.arrow.outer-17 {
  -webkit-animation-delay: -0.9444444444s;
  animation-delay: -0.9444444444s;
}

.arrow.outer-18 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.arrow.inner-1 {
  -webkit-animation-delay: -0.1666666667s;
  animation-delay: -0.1666666667s;
}

.arrow.inner-2 {
  -webkit-animation-delay: -0.3333333333s;
  animation-delay: -0.3333333333s;
}

.arrow.inner-3 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.arrow.inner-4 {
  -webkit-animation-delay: -0.6666666667s;
  animation-delay: -0.6666666667s;
}

.arrow.inner-5 {
  -webkit-animation-delay: -0.8333333333s;
  animation-delay: -0.8333333333s;
}

.arrow.inner-6 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0.1;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

.fact {
  display: flex;
  align-items: baseline;
  text-align: center;
  position: absolute;
  bottom: 85px;
  letter-spacing: 2px;
  height: auto;
  font-size: x-large;
  color: aliceblue;
  bottom: 5%;
  padding: 20px;
}

#aa2708 {
  color: #aa2708;
}

#d4d41a {
  color: #d4d41a;
}

#2e6fd7 {
  color: #2e6fd7;
}

#warning {
  display: flex;
  justify-content: center;
  font-size: x-large;
  padding-bottom: 10px;
  font-weight: bold;
  font-weight: 100;
}

/* .arrow {
      background-image: url("/down-arrow.png");
      background-size: contain;
      position: relative;
      width: 25px;
      top: 6px;
      height: 36px;
      left: 25px;
      background-repeat: no-repeat;
    }
    
    .out { 
      top: 494px;
      position: relative;
      left: -25px;
    } */

#game-container { 
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-flow: wrap;
  filter: blur(10px);
}

#game-container hr {
  width: 100%;
  display: none;
}

#fuel-indicator {
  width: 25px;
  height: 25px;
  margin: 0;
}

#fuel-indicator img {
  height: 100%;
  width: 100%;
}

#gameBox {
  font-family: 'VT323', monospace;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  gap: 2em;
  top: 10%;
  color: white;
}

#game-info {
  display: flex;
  justify-content: center;
  height: auto;
}

.game-child-info {
  width: 525px;
  display: flex;
  justify-content: space-between;
}

.game-child-info div,
.instructionFire{
  font-size: larger;
  /* margin: 10px; */
  display: flex;
}

.game-child-info span {
  font-weight: bold;
}

#scoreInfo {
  display: none;
  font-size: 20px;
  margin: 10px;
  justify-content: center;
  position: relative;
  bottom: 66px;
}

#scoreInfo span {
  font-weight: bold;
}

#game-screen {
  display: flex;
  transition: filter 1s;
}

@keyframes blurr {
  from {
    filter: blur(0px);
  }

  to {
    filter: blur(10px);
  }
}

.cell {
  width: 25px;
  height: 25px;
  /* border: 1px solid #ccc; */
  background-color: #540d0d;
  /* background-color: transparent; */
}

.wall {
  background-color: #333;
  background-image: url(https://paraminnovation.org/wp-content/uploads/2023/04/lava.webp);
  background-size: cover;
}

.fuel {
  /* background-color: #f00; */
  background-image: url(https://paraminnovation.org/wp-content/uploads/2023/04/fuel.webp);
  background-size: contain;
}

@keyframes opacity {
  from {
    opacity: 0
  }

  ;

  to {
    opacity: 1
  }

  ;
}

.water {
  /* background-color: transparent; */
  /* background-image: url("/splash.png"); */
  background-image: url("https://paraminnovation.org/wp-content/uploads/2023/04/watr.gif");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 5s ease-in-out;
  opacity: 1;
  /* animation: fadeInOut 2s infinite; */
}

.fade {
  transition: background-image 2 ease-in-out;
  background-image: none !important;
}

.player {
  /* background-color: #0f0; */
  position: relative;
  /* background-image: url("pixel-fire2.gif"); */
  background-image: url("https://paraminnovation.org/wp-content/uploads/2023/04/fire.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.exit {
  position: relative;
}

.exit:before,
.exit:after,
.exit .right-border,
.exit .crown>div {
  content: "";
  position: absolute;
  background-color: #FFD700;
}

.exit:before {
  left: 10%;
  width: 80%;
  height: 3px;
}

.exit:after {
  top: 10%;
  left: 10%;
  width: 3px;
  height: 85%;
  border-right: 2px solid #000;
}

.exit .right-border {
  position: absolute;
  top: 8%;
  right: 10%;
  width: 3px;
  height: 85%;
  background-color: #FFD700;
}

.exit .crown {
  position: absolute;
  left: 10%;
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.exit .crown>div {
  width: 4px;
  height: 8px;
  transform-origin: bottom center;
  transform: rotate(45deg);
}

.message {
  display: flex;
  justify-content: center;
}

.lowFuel {
  background-image: url("https://paraminnovation.org/wp-content/uploads/2023/04/lowFuel.gif");
  background-size: contain;
  height: 40vh;
  width: 350px;
  position: absolute;
  top: 21%;
  border-radius: 2rem;
  color: white;
}

.low {
  display: none;
  justify-content: center;
  cursor: pointer;
  animation: gifLoad 1s ease-in-out;
}

.gif {
  /* display: flex; */
  display: none;
  justify-content: center;
  cursor: pointer;
  animation: gifLoad 1s ease-in-out;
}

@keyframes gifLoad {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.gif p,
.low p {
  position: absolute;
  z-index: 2;
  /* font-size: larger; */
  font-weight: bold;
}

.gameOverFire p,
.win p {
  color: #000;
}

.gameOverFire {
  background-image: url("https://paraminnovation.org/wp-content/uploads/2023/04/GameOver.gif");
  height: 45vh;
  width: 350px;
  position: absolute;
  top: 21%;
  border-radius: 2rem;
}

.wins {
  display: none;
  justify-content: center;
  cursor: pointer;
  animation: gifLoad 1s ease-in-out;
}

.wins p {
  position: absolute;
  z-index: 2;
  font-size: larger;
  /* top: 13%; */
  font-weight: bold;
}

.win {
  background-image: url("https://paraminnovation.org/wp-content/uploads/2023/04/fire-win.gif");
  background-position: center;
  position: absolute;
  border-radius: 2rem;
}

.promptBox {
  height: 40vh;
  width: 350px;
  top: 23%;
}

.buttonsFire {
  position: absolute;
  display: flex;
  gap: 2rem;
  bottom: 30%;
}

.buttonsFire button {
  border-radius: 20px;
  padding: 8px;
  width: 158px;
  border: none;
  cursor: pointer;
  color: #000;
  font-weight: bold;
}

.buttonsFire button a {
  color: #000;
  text-decoration: none;
}

.buttonsFire button:nth-child(1):hover {
  box-shadow: 0 0 4px 4px #02db02;
  background-image: radial-gradient(white, #e5cfcf, #02db02);
}

.buttonsFire button:nth-child(2):hover {
  box-shadow: 0 0 4px 4px #ff3700;
  background-image: radial-gradient(white, #e5cfcf, #ff3700);
}

.button-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 100px auto;
}

.movement-button {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  text-align: center;
  width: 50px;
}

.up {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.down {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.button-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 100px auto;
}

.movement-button {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  text-align: center;
  width: 50px;
}

.up {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.down {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.instructionFire {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  backdrop-filter: blur(10px);
  border: 2px solid;
  border-radius: 20px;
  padding: 2%;
  position: absolute;
  height: auto;
  width: fit-content;
}

.instructionFire div {
  display: flex;
}

.instructionFire button {
  color: white;
  width: 100px;
  align-self: center;
  border-radius: 10px;
  background-color: #6d1c0e;
  height: 40px;
}

.arrows {
  background-image: url("https://paraminnovation.org/wp-content/uploads/2023/04/arrows.gif");
  background-size: contain;
  width: 90px;
  height: 75px;
  background-repeat: no-repeat;
}

.swipe {
  background-image: url("https://paraminnovation.org/wp-content/uploads/2023/04/swipe.gif");
  background-size: contain;
  width: 90px;
  height: 50px;
  background-repeat: no-repeat;
}

#desk-instruction {
  display: none;
  /* display: none; */
  flex-direction: column;
  gap: 1rem;
}

#desk-instruction .instructionPara {
  padding-top: 41px;
  font-size: larger;
}

#mob-instruction {
  display: none;
  /* display: flex; */
  flex-direction: column;
  gap: 1rem;
}

#mob-instruction .instructionPara{
  padding-top: 20px;
  font-size: small;
}

#mob-instruction button {
  font-size: small;
  height: 30px;
  width: 80px;
}


.key {
  background-image: url("https://paraminnovation.org/wp-content/uploads/2023/04/key.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}


@media only screen and (max-width: 768px) {
  .game-child-info div, .instructionFire {
    font-size: initial;
  }

  #gameBox{
    gap: 1em;
  }
}


@media only screen and (max-width: 450px) {

  .fact {
    font-size: small;
  }

  .game-child-info {
    justify-content: space-around;
  }

  .game-child-info div {
    font-size: small;
  }

  .promptBox {
    top: 33%;
    height: 14rem;
    width: 17rem;
  }

  .gameOverFire {
    background-size: cover;
  }

  .lowFuel,
  .win {
    background-size: contain;
  }

  .buttonsFire {
    gap: 1rem;
    bottom: 34%;
  }

  .buttonsFire button {
    width: 125px;
  }

  .gif p,
  .low p {
    padding: 10px;
    font-size: smaller;
  }

  .bt-52 {
    bottom: vh;
  }

  .bt-38 {
    bottom: 38vh;
  }

  #warning {
    font-size: small;
  }

  #mob-instruction{
    display: flex;
  }

  #desk-instruction{
    display: none;
  }

}

