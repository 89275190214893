body {
	background: #080808;
	font-family: 'DM Sans', sans-serif;
}

#wrap {
	width: 600px;
	height: 600px;
	background: #222;
	box-shadow: inset 0 0 0 1px #b2a1a1, 0 0 0 1px #000;
	left: 50%;
	opacity: 0;
	padding: 10px;
	position: absolute;
	/* top: 50%; */
	transition: opacity 1.5s;
    margin-left: -322px;
    margin-top: 0 !important;
}

.loaded #wrap {
	opacity: 1;
}

#wrap-inner {
	background: #000;
	overflow: hidden;
	position: relative;
}

#cbg1, #cbg2, #cbg3, #cbg4, #cfg, #cmg {
	cursor: crosshair;
	display: block;
	margin: 0 !important;
	left: 0;
	position: absolute;
	top: 0;
	z-index: 1;
	width: inherit;
}

#cfg {
	background-image: linear-gradient(rgba(1, 1, 1, 0.2), rgba(1, 1, 1, 0.6) ),url('../earthimage1.png');

    background-size: 20%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 0;
}