.highlights .highlightContent {
  width: 100%;
  padding-top: 11%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-color: #0b0c10;
  gap: 10vh;
  padding-bottom: 10%;
}

.highlights .highlightContent .highlightContentContainer {
  width: 80%;
  display: flex;
}

.highlights .highlightContent .highlightContentContainer .about,
.highlights .highlightContent .highlightContentContainer .dateNTime {
  width: 50%;
}

.highlights .highlightContent .highlightContentContainer .about h2,
.highlights .highlightContent .otherHighlights h2 {
  opacity: 0.5;
  font: normal normal bold 2.1vw/4.4vw Helvetica Neue;
  letter-spacing: 0;
  margin: 0;
}

.highlights
  .highlightContent
  .otherHighlights
  .highlightData
  .highlightCard
  .highlightCardContent
  h2 {
  opacity: 1;
}

.highlights
  .highlightContent
  .otherHighlights
  .highlightData
  .highlightCard
  .highlightCardContent
  p {
  opacity: 0.5;
}

.highlights .highlightContent .highlightContentContainer .about p,
.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  p,
.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  .dateTime
  p,
.highlights
  .highlightContent
  .otherHighlights
  .highlightData
  .highlightCard
  .highlightCardContent
  p {
  font: normal normal normal 1.04vw/1.55vw Helvetica Neue;
  letter-spacing: 0.05vw;
  padding-right: 5%;
  margin: 0;
}

.highlights .highlightContent .highlightContentContainer .dateNTime {
  background-size: cover;
  height: 80vh;
  display: flex;
  align-items: flex-end;
  border-radius: 30px;
  overflow: hidden;
}

.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer {
  width: 90%;
  padding: 5%;
  display: flex;
  padding: 5%;
  flex-direction: column;
  gap: 2vh;
  backdrop-filter: blur(100px);
  background-image: linear-gradient(
    rgba(11, 12, 16, 0.2),
    rgba(11, 12, 16, 0.4),
    rgba(11, 12, 16, 0.6),
    rgb(11, 12, 16, 0.8),
    rgb(11, 12, 16)
  );
}

.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  h2,
.highlights
  .highlightContent
  .otherHighlights
  .highlightData
  .highlightCard
  .highlightCardContent
  h2 {
  width: 100%;
  font: normal normal bold 1.55vw/1.9vw Helvetica Neue;
  letter-spacing: 1.5px;
  letter-spacing: 0.08vw;
}

.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  .dateTime,
.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  .dateTime
  .time {
  display: flex;
}

.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  .dateTime {
  gap: 5%;
}

.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  .bookTicket {
  width: max-content;
  background-color: #d7326e;
  border-radius: 30px;
  padding: 2% 3%;
}

.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  .bookTicket:hover {
  cursor: pointer;
}

.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  .dateTime
  h2 {
  font: normal normal bold 3.1vw/3.85vw Helvetica Neue;
  letter-spacing: 0.09vw;
}

.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  .dateTime
  h2,
.highlights
  .highlightContent
  .highlightContentContainer
  .dateNTime
  .dateNTimeContainer
  .dateTime
  p {
  margin: 0;
}

.highlights .highlightContent .otherHighlights {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  width: 80%;
}

.highlights .highlightContent .otherHighlights .highlightData {
  width: 100%;
  display: flex;
  gap: 5%;
}

.highlights .highlightContent .otherHighlights .highlightData .highlightCard {
  width: 30%;
  height: 50vh;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  border-radius: 30px;
  overflow: hidden;
}

.highlights .highlightContent .otherHighlights .highlightData .highlightCard a {
  text-decoration: none;
  color: white;
  width: 100%;
}

.highlights
  .highlightContent
  .otherHighlights
  .highlightData
  .highlightCard
  .highlightCardContent {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  padding: 5%;
  height: 33%;
  backdrop-filter: blur(100px);
  background-image: linear-gradient(
    rgba(11, 12, 16, 0.2),
    rgba(11, 12, 16, 0.4),
    rgba(11, 12, 16, 0.6),
    rgb(11, 12, 16, 0.8),
    rgb(11, 12, 16)
  );
}

.highlights .parsec.footer .mapContainer {
  display: none;
}

@media screen and (max-width: 450px) {
  .highlights .highlightContent {
    padding-top: 19%;
  }

  .highlights .highlightContent .highlightContentContainer {
    gap: 5vh;
    width: 90%;
    flex-direction: column-reverse;
  }

  .highlights .highlightContent .highlightContentContainer .about,
  .highlights .highlightContent .highlightContentContainer .dateNTime {
    width: 100%;
  }

  .highlights .highlightContent .highlightContentContainer .dateNTime {
    height: 70vh;
  }

  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    .dateTime {
    gap: 5%;
    justify-content: space-between;
  }

  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    .dateTime
    .date,
  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    .dateTime
    .time {
    backdrop-filter: blur(100px);
    border-radius: 12px;
    padding: 5% 7%;
  }

  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    .dateTime
    .time {
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
  }

  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    h2,
  .highlights
    .highlightContent
    .otherHighlights
    .highlightData
    .highlightCard
    .highlightCardContent
    h2 {
    width: 100%;
    font: normal normal bold 1.75vh / 2.2vh Helvetica Neue;
    letter-spacing: 0.08vh;
  }

  .highlights .highlightContent .highlightContentContainer .about p,
  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    p,
  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    .dateTime
    p,
  .highlights
    .highlightContent
    .otherHighlights
    .highlightData
    .highlightCard
    .highlightCardContent
    p {
    padding-right: 0;
  }

  .highlights .highlightContent .highlightContentContainer .about p,
  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    p,
  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    .dateTime
    p,
  .highlights
    .highlightContent
    .otherHighlights
    .highlightData
    .highlightCard
    .highlightCardContent
    p,
  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    .bookTicket {
    font: normal normal normal 1.64vh / 2.45vh Helvetica Neue;
    letter-spacing: 0.08vh;
  }

  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    .dateTime
    p {
    font-size: 1.25vh;
    letter-spacing: 0.06vh;
  }

  .highlights
    .highlightContent
    .highlightContentContainer
    .dateNTime
    .dateNTimeContainer
    .dateTime
    h2 {
    font: normal normal bold 3.1vh / 3.85vh Helvetica Neue;
    letter-spacing: 0.09vh;
  }

  .highlights .highlightContent .highlightContentContainer .about h2,
  .highlights .highlightContent .otherHighlights h2 {
    opacity: 0.5;
    font: normal normal bold 1.85vh / 2.4vh Helvetica Neue;
    letter-spacing: 0.1vh;
  }

  .highlights .highlightContent .otherHighlights {
    width: 90%;
  }

  .highlights .highlightContent .otherHighlights .highlightData {
    flex-direction: column;
    gap: 5vh;
  }

  .highlights .highlightContent .otherHighlights .highlightData .highlightCard {
    width: 100%;
    height: 35vh;
  }

  .highlights
    .highlightContent
    .otherHighlights
    .highlightData
    .highlightCard
    .highlightCardContent
    p {
    opacity: 0.5;
  }
}
