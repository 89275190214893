html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111013 !important;
  position: absolute;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fa-regular {
  font-size: larger;
}

.expHomePage .App {
  text-align: center;
}

.expHomePage .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.expHomePage .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.expHomePage .App-link {
  color: #61dafb;
}

.expHomePage .superContainer {
  height: 100vh;
  width: 100vw;
}

.expHomePage .nav {
  position: fixed;
  z-index: 1;
  background-color: transparent !important;
}

.expHomePage .nav #logo {
  background-image: url(/public/logo.png);
}

.expHomePage .nav .home {
  display: none;
}

.expHomePage .nav .sidebarButton {
  display: flex !important;
  position: fixed;
  right: 8%;
  z-index: 2;
  gap: 3%;
}

/* ------------------------------SIDEBAR BUTTON------------------------------ */
.expHomePage .nav .btn {
  position: absolute;
  width: 20px;
  cursor: pointer;
  height: 20%;
}

.expHomePage .nav .btn span {
  display: block;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);
  border-radius: 3px;
  height: 3px;
  background: #fff;
  transition: all .3s;
  position: relative;
}

.expHomePage .nav .active span:nth-child(1) {
  animation: ease .7s top forwards;
}

.expHomePage .nav .not-active span:nth-child(1) {
  animation: ease .7s top-2 forwards;
}

.expHomePage .nav .active span:nth-child(2) {
  animation: ease .7s scaled forwards;
}

.expHomePage .nav .not-active span:nth-child(2) {
  animation: ease .7s scaled-2 forwards;
}

.expHomePage .nav .active span:nth-child(3) {
  animation: ease .7s bottom forwards;
}

.expHomePage .nav .not-active span:nth-child(3) {
  animation: ease .7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 0px;
    transform: rotate(0);
  }
  100% {
    top: -6.5px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: -6.5px;
    transform: rotate(45deg);
  }
  50% {
    top: 0px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 0px;
    transform: rotate(0);
  }
  100% {
    bottom: -6.5px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: -6.5px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 0px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* ------------------------------SIDEBAR BUTTON------------------------------ */

.expHomePage .nav .sideBar {
  position: fixed;
  height: 100vh;
  width: 70vw;
  top: 0;
  right: -100vw;
  display: flex !important;
  flex-direction: column;
  background-color: #000000;
  padding: 15% 0 0 10%;
  justify-content: normal;
  gap: 3%;
  transition: 0.5s ease-in-out;
  /* z-index: 2; */
}

.expHomePage .nav .sideBar div {
  font-size: 1.78vh;
}

.expHomePage .nav .sideBar a {
  text-decoration: none;
  color: white;
}

.nav {
  align-items: center;
  background: transparent;
  color: #fff;
  display: flex !important;
  font-family: DM Sans, Sans-serif;
  font-size: 1vw;
  font-weight: 400;
  gap: 20px;
  /* gap: 40px; */
  height: 10%;
  padding: 2%;
  padding-left: 4% !important;
  position: absolute;
  width: -webkit-fill-available;
  z-index: 1005;
}

.nav div {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
}

.nav div p {
  height: min-content;
}

.nav div p a {
  text-decoration: none;
  color: white;
}

#logo {
  background: url("/public/logo.png");
  background-size: cover;
  width: 6vw;
  height: 6vw;
  background-repeat: no-repeat;
  background-position: top;
}

.expHomePage .expContainer {
  width: 100%;
  position: absolute;
}

.expContainer .firstInteraction {
  position: relative;
  display: flex;
  justify-content: center; 
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.expContainer .firstInteractionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  /* width: 50%;
  height: 35%; */
  bottom: 4%;
  margin: 0;
}

.expContainer .firstInteraction .pageContent {
  display: none;
}

.expContainer .firstInteraction .pageContent,
.expContainer .firstInteraction .scrollDown {
  font-size: 1.3vw;
  line-height: 2.09vw;
}

.expContainer .firstInteraction .scrollDown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.expContainer .firstInteraction .scrollDown p {
  margin: 0;
  font-weight: bold;
}

.expContainer .firstInteraction .scrollDown .scrollIcon {
  width: 25%;
}

.expContainer .firstInteraction .scrollDown svg {
  height: 100%;
  width: 100%;
}

/* .expContainer .firstInteraction .pageContent .scrollDown {
  position: absolute;
  width: 50%;
  height: 20%;
  bottom: 0;
  margin: 0;

} */

.expHomePage .expContainer .splineScene {
  height: 100vh !important;
}

.expHomePage .expContainer .splineScene canvas {
  position: fixed;
  background-color: #171717;
  /* scale: 1.3; */
}

.expHomePage .expImg {
  padding: 50px;
  justify-content: space-evenly;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/public/ExperiencePage/exprience.png);
  /* width: 100%; */
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white;
}

.expHomePage .expTag {
  font-family: "DM Sans", Sans-serif;
  flex-direction: column;
  display: flex;
  width: 50%;
  /* gap: 2rem; */
  margin-top: 15%;
}

.expHomePage .expTag h1 {
  color: #ecf2ff;
  font-family: "DM Sans", Sans-serif;
  font-size: 5.6vw;
  height: auto;
  font-weight: 400;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.1em;
  letter-spacing: 0px;
}

.expHomePage .expTag p {
  line-height: 1.5vw;
  font-size: 1vw;
  width: 28vw;
}

.expHomePage .cardsContainer {
  /* margin-top: 100vh; */
  padding: 5%;
  padding-bottom: 20%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  gap: 6rem;
  flex-wrap: wrap;
  --widgets-spacing: 20px;
  background-color: #11101300;
  position: relative;
  z-index: 1;
  background: transparent linear-gradient(180deg, #191a1f 0%, #212c5a 100%) 0%
    0% no-repeat padding-box;
  font-family: sans-serif;
}

.expHomePage .cardsContainer .cardContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.expHomePage .cardsContainer .cardContent h1,
.expHomePage .cardsContainer .cardContent p {
  width: 50%;
}

.expHomePage .cardsContainer .cardContent h1 {
  font-weight: 900;
  font-size: 3.2vw;
  line-height: 3.77vw;
  margin: 0;
  color: white;
}

.expHomePage .cardsContainer .cardContent p {
  font-size: 1.4vw;
  line-height: 2.06vw;
  text-align: justify;
}

.expHomePage .cardsContainer .cardContent h1 span {
  color: #3a5df0;
}

.expHomePage .cardHolder {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  /* padding-left: 5%; */
  justify-content: center;
  /* padding-right: 5%; */
  width: 100%;
}

.expHomePage .cardSlot {
  /* width: 20vw;
  height: 70vh; */

  width: 27.77vw;
  height: 20.8vw;
  display: flex;
  gap: 2%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.expHomePage .cardDisplay {
  border-radius: 20px;
  width: 100% !important;
  height: 100%;
  position: relative;
  background-position: center;
  transition: 0.3s ease-in-out;
}

.expHomePage .cardDisplay:hover {
  box-shadow: 5px 5px 50px #28397e;
}

.expHomePage .cardDisplay .gradient {
  height: 80%;
  border-radius: 20px 20px 0 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.75)
  );
}

.expHomePage #indianMinds {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("/public/ExperiencePage/Indianminds.webp");
  background-size: cover;
}

.expHomePage #virtualZone {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("/public/ExperiencePage/metaverse.webp");
  background-size: cover;
  background-position: center;
}

.expHomePage #gamingZone {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("/public/ExperiencePage/gaming.webp");
  background-size: cover;
  background-position: center;
}

.expHomePage #rangolify {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("/public/ExperiencePage/rangolify.webp");
  background-size: cover;
  background-position: center;
}

.expHomePage #curaspace {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("/public/ExperiencePage/curaspace.webp");
  background-size: cover;
  background-position: center;
}

.expHomePage #fos {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("/public/ExperiencePage/Fos.png");
  background-size: cover;
  background-position: center;
}

.expHomePage #equGallery {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("/public/ExperiencePage/equationGallery.svg");
  background-size: cover;
  background-position: center;
}

.expHomePage #pitchDeck {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("/public/ExperiencePage/pitchDeck.png");
  background-size: cover;
  background-position: center;
}
.expHomePage #scienceApp {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("https://digitalassestbucket.s3.amazonaws.com/ScienceApplication/1.png");
  background-size: cover;
  background-position: center;
}
.expHomePage #evolutionSci {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("https://digitalassestbucket.s3.amazonaws.com/SciEvolution/evolutionSci.png");
  background-size: cover;
  background-position: center;
}

.expHomePage #funSci {
  /* background-color: #787878; */
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url("https://digitalassestbucket.s3.amazonaws.com/FunScience/Fun+things+to+do+with+science-min.webp");
  background-size: cover;
  background-position: center;
}

.expHomePage #parsec {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url(https://digitalassestbucket.s3.amazonaws.com/IncityGallery/Incity+cover+image.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}


.expHomePage #avatar {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url(https://digitalassestbucket.s3.amazonaws.com/SciEvolution/science+avatar.webp);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}


.expHomePage #ContraversialPoll {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1)
    ),
    url(https://digitalassestbucket.s3.amazonaws.com/ScienceApplication/contraversial.webp);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.buttonContainer {
  width: 100% !important;
  height: 20% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.buttonContainerNew {
  width: 100% !important;
  height: 20% !important;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  position: absolute;
  bottom: 0;
  background-color: #000000;
  border-radius: 0 0 20px 20px;
}

.buttonContainerNew .button {
  background-color: transparent;
  font-size: 1.4vw;
  font-weight: 600;
}

.button {
  background-color: #6c4ab6;
  padding: 0px 15px;
  width: max-content !important;
  border-radius: 60px;
  height: 40% !important;
  padding: 0px 15px !important;
  align-items: center;
  display: flex;
  color: white;
  font-family: "DM Sans", Sans-serif;
  font-size: 1.1vw;
  font-weight: 400;
  text-decoration: none;
}

.button:hover {
  cursor: pointer;
  color: white;
}

@media screen and (max-width: 1024px) {
  .expHomePage .cardSlot {
    width: 25%;
  }
}

@media screen and (max-width: 925px) {
  .expHomePage .cardSlot {
    width: 40%;
    height: 70vh;
  }

  .expHomePage .expTag h1 {
    font-size: 5.6vh;
  }
}

@media screen and (max-width: 450px) {
  #logo {
    height: 7vh;
    width: 7vh;
  }

  .nav div p a {
    font-size: 1.2vh;
  }

  .button {
    font-size: 1.5vh;
  }

  .expHomePage #fosLogo {
    display: none;
  }

  .expHomePage .expImg {
    padding: 15% 50% 0% 4%;
    background-size: cover;
    background-position-x: 90%;
  }

  .expHomePage .cardsContainer {
    gap: 3rem;
    padding-bottom: 52%;
  }

  .expHomePage .expContainer .splineScene canvas {
    scale: 1.3;
  }

  .expContainer .firstInteractionContent {
    /* width: 80%;
    height: 45%; */
  }

  .expContainer .firstInteraction .pageContent,
  .expContainer .firstInteraction .scrollDown {
    font-size: 1.8vh;
    line-height: 2.7vh;
    text-align: center;
  }

  .expHomePage .cardsContainer .cardContent {
    width: 90%;
    flex-direction: column;
  }

  .expHomePage .cardsContainer .cardContent h1,
  .expHomePage .cardsContainer .cardContent p {
    width: 100%;
  }

  .expHomePage .cardsContainer .cardContent h1 {
    font-size: 4.45vh;
    line-height: 5.45vh;
  }

  .expHomePage .cardsContainer .cardContent p,
  .buttonContainerNew .button {
    padding-top: 5%;
    font-size: 1.67vh;
    line-height: 2.67vh;
  }

  /* .expHomePage .cardsContainer .cardContent h1 span {
    line-height: 8.4vh;
  } */

  .expHomePage .expTag {
    width: 100vw;
    height: 100%;
    align-items: center;
    gap: 1rem;
    position: absolute;
    top: 5%;
  }

  .expHomePage .expTag h1 {
    font-size: 5.6vh;
    height: auto;
    width: 99vw;
  }

  .expHomePage .expTag p {
    width: 99vw;
    line-height: 4vw;
    font-size: 1.5vh;
  }

  .expHomePage .cardHolder {
    width: 90%;
    justify-content: center;
    gap: 2rem;
    padding: 0;
  }

  .expHomePage .cardHolder a {
    width: 100%;
  }

  .expHomePage .cardSlot {
    width: 100% !important;
    height: 25vh;
  }

  .expHomePage #logo {
    background-image: url("/public/logo.png");
    background-size: contain;
    /* width: 45px; */
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 340px) {
  .expHomePage .cardSlot {
    height: 60vh;
  }
}
