  /*-------------------------- Android and Windows devices --------------------------*/
  @supports not (-webkit-overflow-scrolling: touch) {
    /* * {
      margin: 0;
      height: 100%;
    } */

    html {
      scroll-behavior: smooth;
    }


    body,
    html {
      height: 100%;
      overflow-x: clip;
      width: -webkit-fill-available;
    }

    .fieldsOfScience #disclaimer {
      display: none;
    }

    .fieldsOfScience #ipadDisclaimer {
      display: none;
    }

    .fieldsOfScience .top-nav {
      position: fixed;
      top: 0;
      width: 100%;
      background-color: transparent;
      height: 50px;
      z-index: 1002;
    }

    .fieldsOfScience top-nav ul {
      display: flex;
      gap: 34px;
      list-style-type: none;
      margin-left: 6.7%;
      margin-top: 12px;
      padding: 0;
      text-align: left;
    }

    .fieldsOfScience top-nav li {
      display: inline;
    }

    .fieldsOfScience top-nav a {
      display: inline-block;
      font-family: 'DM Sans';
      color: #fff;

      text-transform: capitalize;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
      transition: 0.3s;
      font-size: 17px;
    }

    /*#logo {
      /* background-image: url("/public/logo.png"); */
    /* background-size: contain;
      width: 65px;
      height: 66px;
      background-repeat: no-repeat;
    } */
    .fieldsOfScience welcome {
      z-index: 1001;
      position: absolute;
      display: none;
    }

    .fieldsOfScience #fosLogo {
      background-image: url("/public/FOSLogo.svg");
      background-position: center;
      background-size: 50%;
      position: fixed;
      width: 20%;
      height: 11%;
      left: 40%;
      background-repeat: no-repeat;
      z-index: 3;
    }

    .fieldsOfScience #dSwipe {
      background: url("/public/swipe.svg");
      background-position: center;
      background-size: 90%;
      position: fixed;
      width: 42px;
      height: 40px;
      left: 48.5%;
      background-repeat: no-repeat;
      bottom: 4%;
      z-index: 3;
    }

    .fieldsOfScience .sContainer {
      height: 100vh;
      width: 100%;
    }

    .fieldsOfScience .first {
      background-image: url('/public/Back/Bg1.svg');
      background-color: #352b5f;
    }

    .fieldsOfScience .first .story #title {
      background: url('/public/SpObjects/Tit1.svg');
    }

    .fieldsOfScience .first .story #img {
      background: url('/public/SpObjects/Img1.svg');
    }

    .fieldsOfScience .first .story #num {
      background: url('/public/SpObjects/Num1.svg');
    }

    .fieldsOfScience .first .story #paraContainer {
      background: url('/public/SpObjects/Dtxt1.svg');
    }

    .fieldsOfScience .second {
      background-image: url("/public/Back/Bg2.svg");
      background-color: #e5e3f1;
    }

    .fieldsOfScience .second .story #title {
      background: url('/public/SpObjects/Tit2.svg');
    }

    .fieldsOfScience .second .story #img {
      background: url('/public/SpObjects/Img2.svg');
    }

    .fieldsOfScience .second .story #num {
      background: url('/public/SpObjects/Num2.svg');
    }

    .fieldsOfScience .second .story #paraContainer {
      background: url('/public/SpObjects/Dtxt2.svg');
    }

    .fieldsOfScience .third {
      background-image: url('/public/Back/Bg3.svg');
      background-color: #eae5f1;
    }

    .fieldsOfScience .third .story #title {
      background: url('/public/SpObjects/Tit3.svg');
    }

    .fieldsOfScience .third .story #img {
      background: url('/public/SpObjects/Img3.svg');
    }

    .fieldsOfScience .third .story #num {
      background: url('/public/SpObjects/Num3.svg');
    }

    .fieldsOfScience .third .story #paraContainer {
      background: url('/public/SpObjects/Dtxt3.svg');
    }

    .fieldsOfScience .fourth {
      background-image: url("/public/Back/Bg4.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .fourth .story #title {
      background: url('/public/SpObjects/Tit4.svg');
    }

    .fieldsOfScience .fourth .story #img {
      background: url('/public/SpObjects/Img4.svg');
    }

    .fieldsOfScience .fourth .story #num {
      background: url('/public/SpObjects/Num4.svg');
    }

    .fieldsOfScience .fourth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt4.svg');
    }

    .fieldsOfScience .fifth {
      background-image: url("/public/Back/Bg5.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .fifth .story #title {
      background: url('/public/SpObjects/Tit5.svg');
    }

    .fieldsOfScience .fifth .story #img {
      background: url('/public/SpObjects/Img5.svg');
    }

    .fieldsOfScience .fifth .story #num {
      background: url('/public/SpObjects/Num5.svg');
    }

    .fieldsOfScience .fifth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt5.svg');
    }

    .fieldsOfScience .sixth {
      background-image: url("/public/Back/Bg6.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .sixth .story #title {
      background: url('/public/SpObjects/Tit6.svg');
    }

    .fieldsOfScience .sixth .story #img {
      background: url('/public/SpObjects/Img6.svg');
    }

    .fieldsOfScience .sixth .story #num {
      background: url('/public/SpObjects/Num6.svg');
    }

    .fieldsOfScience .sixth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt6.svg');
    }

    .fieldsOfScience .seventh {
      background-image: url("/public/Back/Bg7.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .seventh .story #title {
      background: url('/public/SpObjects/Tit7.svg');
    }

    .fieldsOfScience .seventh .story #img {
      background: url('/public/SpObjects/Img7.svg');
    }

    .fieldsOfScience .seventh .story #num {
      background: url('/public/SpObjects/Num7.svg');
    }

    .fieldsOfScience .seventh .story #paraContainer {
      background: url('/public/SpObjects/Dtxt7.svg');
    }

    .fieldsOfScience .eight {
      background-image: url("/public/Back/Bg8.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .eight .story #title {
      background: url('/public/SpObjects/Tit8.svg');
    }

    .fieldsOfScience .eight .story #img {
      background: url('/public/SpObjects/Img8.svg');
    }

    .fieldsOfScience .eight .story #num {
      background: url('/public/SpObjects/Num8.svg');
    }

    .fieldsOfScience .eight .story #paraContainer {
      background: url('/public/SpObjects/Dtxt8.svg');
    }

    .fieldsOfScience .ninth {
      background-image: url("/public/Back/Bg9.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .ninth .story #title {
      background: url('/public/SpObjects/Tit9.svg');
    }

    .fieldsOfScience .ninth .story #img {
      background: url('/public/SpObjects/Img9.svg');
    }

    .fieldsOfScience .ninth .story #num {
      background: url('/public/SpObjects/Num9.svg');
    }

    .fieldsOfScience .ninth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt9.svg');
    }

    .fieldsOfScience .tenth {
      background-image: url("/public/Back/Bg10.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .tenth .story #title {
      background: url('/public/SpObjects/Tit10.svg');
    }

    .fieldsOfScience .tenth .story #img {
      background: url('/public/SpObjects/Img10.svg');
    }

    .fieldsOfScience .tenth .story #num {
      background: url('/public/SpObjects/Num10.svg');
    }

    .fieldsOfScience .tenth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt10.svg');
    }

    .fieldsOfScience .eleventh {
      background-image: url("/public/Back/Bg11.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .eleventh .story #title {
      background: url('/public/SpObjects/Tit11.svg');
    }

    .fieldsOfScience .eleventh .story #img {
      background: url('/public/SpObjects/Img11.svg');
    }

    .fieldsOfScience .eleventh .story #num {
      background: url('/public/SpObjects/Num11.svg');
    }

    .fieldsOfScience .eleventh .story #paraContainer {
      background: url('/public/SpObjects/Dtxt11.svg');
    }

    .fieldsOfScience .twelfth {
      background-image: url("/public/Back/Bg12.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .twelfth .story #title {
      background: url('/public/SpObjects/Tit12.svg');
    }

    .fieldsOfScience .twelfth .story #img {
      background: url('/public/SpObjects/Img12.svg');
    }

    .fieldsOfScience .twelfth .story #num {
      background: url('/public/SpObjects/Num12.svg');
    }

    .fieldsOfScience .twelfth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt12.svg');
    }

    .fieldsOfScience .thirteenth {
      background-image: url("/public/Back/Bg13.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .thirteenth .story #title {
      background: url('/public/SpObjects/Tit13.svg');
    }

    .fieldsOfScience .thirteenth .story #img {
      background: url('/public/SpObjects/Img13.svg');
    }

    .fieldsOfScience .thirteenth .story #num {
      background: url('/public/SpObjects/Num13.svg');
    }

    .fieldsOfScience .thirteenth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt13.svg');
    }

    .fieldsOfScience .fourtheenth {
      background-image: url("/public/Back/Bg14.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .fourtheenth .story #title {
      background: url('/public/SpObjects/Tit14.svg');
    }

    .fieldsOfScience .fourtheenth .story #img {
      background: url('/public/SpObjects/Img14.svg');
    }

    .fieldsOfScience .fourtheenth .story #num {
      background: url('/public/SpObjects/Num14.svg');
    }

    .fieldsOfScience .fourtheenth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt14.svg');
    }

    .fieldsOfScience .fifteenth {
      background-image: url("/public/Back/Bg15.svg");
      background-color: #ffd2c5;
    }

    .fieldsOfScience .fifteenth .story #title {
      background: url('/public/SpObjects/Tit15.svg');
    }

    .fieldsOfScience .fifteenth .story #img {
      background: url('/public/SpObjects/Img15.svg');
    }

    .fieldsOfScience .fifteenth .story #num {
      background: url('/public/SpObjects/Num15.svg');
    }

    .fieldsOfScience .fifteenth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt15.svg');
    }

    .fieldsOfScience .first,
    .fieldsOfScience .second,
    .fieldsOfScience .third,
    .fieldsOfScience .fourth,
    .fieldsOfScience .fifth,
    .fieldsOfScience .sixth,
    .fieldsOfScience .seventh,
    .fieldsOfScience .eight,
    .fieldsOfScience .ninth,
    .fieldsOfScience .tenth,
    .fieldsOfScience .eleventh,
    .fieldsOfScience .twelfth,
    .fieldsOfScience .thirteenth,
    .fieldsOfScience .fourtheenth,
    .fieldsOfScience .fifteenth { 
      display: flex;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100vh;
      position: relative;
      overflow: hidden;
      z-index: 2;
    }

    .fieldsOfScience .story {
      margin: 0 auto;
      min-width: 980px;
      width: 100%;
      position: relative;
    }

    .fieldsOfScience .story #title {
      background-repeat: no-repeat !important;
      /* background-attachment: fixed !important; */
      background-size: auto 19% !important;
      background-position-y: 76% !important;
      z-index: 2;
      height: 100%;
    }

    .fieldsOfScience .story #img {
      background-repeat: no-repeat !important;
      background-attachment: fixed !important;
      background-size: 32% !important;
      background-position: 73.2% 68% !important;
      height: 100%
    }

    .fieldsOfScience .story #num {
      background-repeat: no-repeat !important;
      background-attachment: fixed !important;
      background-size: 10% !important;
      background-position: 50.2% 74% !important;
      height: 100%
    }

    .fieldsOfScience .story #paraContainer {
      background-repeat: no-repeat !important;
      background-attachment: fixed !important;
      background-size: 35% !important;
      background-position: 11% 33% !important;
      z-index: 1;
      height: 100%
    }

    .fieldsOfScience .story div {
      position: absolute;
      width: inherit;
    }

    .fieldsOfScience .eleventh .story #num {
      background-size: 9% !important;
    }

    .fieldsOfScience .side-nav {
      position: fixed;
      right: 8%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;
    }

    .fieldsOfScience .side-nav ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .fieldsOfScience .side-nav ul li {
      margin-bottom: 20px;
      height: 15px;
    }

    .fieldsOfScience .side-nav ul li a {
      display: block;
      background: #AD7B10 0% 0% no-repeat padding-box;
      height: 12px;
      width: 12px;
      opacity: 0.5;
      border-radius: 50%;
      text-align: center;
      line-height: 15px;
      color: #fff;
      text-decoration: none;
    }

    .fieldsOfScience .side-nav ul li a p {
      color: #AD7B10;
      font-family: 'DM Sans';
      font-size: 18px;
      position: relative;
      /* top: -14px; */
      text-align: end;
      width: 122px;
      height: 29px;
      left: -91px;
    }

    .fieldsOfScience .side-nav ul li a p {
      transition: transform 0.5s ease, opacity 0.5s ease;
      /* Adjust the timing as needed */
      transform: translateX(0);
      /* Adjust the distance as needed */
      opacity: 0;
    }

    .fieldsOfScience .side-nav ul li a:hover p {
      transform: translateX(-45px);
      opacity: 1;
    }


    .fieldsOfScience .side-nav ul li a:hover {
      opacity: 1 !important;
    }

    /* active class */
    .fieldsOfScience .side-nav ul li a.active {
      opacity: 1;
    }

    .fieldsOfScience .active {
      opacity: 1;
    }

    .fieldsOfScience .mobile {
      display: none;
    }

    .fieldsOfScience .m-story {
      margin: 0 auto;
      overflow: auto;
      width: 100%;
    }

    /******************************************  Mobile view  ******************************************/

    .fieldsOfScience .mobile .sContainer .first {
      background-image: url("/public/MBack/PBg1.svg");
    }

    .fieldsOfScience .mobile .sContainer .second {
      background-image: url("/public/MBack/PBg2.svg");
    }

    .fieldsOfScience .mobile .sContainer .third {
      background-image: url("/public/MBack/PBg3.svg");
    }

    .fieldsOfScience .mobile .sContainer .fourth {
      background-image: url("/public/MBack/PBg4.svg");
    }

    .fieldsOfScience .mobile .sContainer .fifth {
      background-image: url("/public/MBack/PBg5.svg");
    }

    .fieldsOfScience .mobile .sContainer .sixth {
      background-image: url("/public/MBack/PBg6.svg");
    }

    .fieldsOfScience .mobile .sContainer .seventh {
      background-image: url("/public/MBack/PBg7.svg");
    }

    .fieldsOfScience .mobile .sContainer .eight {
      background-image: url("/public/MBack/PBg8.svg");
    }

    .fieldsOfScience .mobile .sContainer .ninth {
      background-image: url("/public/MBack/PBg9.svg");
    }

    .fieldsOfScience .mobile .sContainer .tenth {
      background-image: url("/public/MBack/PBg10.svg");
    }

    .fieldsOfScience .mobile .sContainer .eleventh {
      background-image: url("/public/MBack/PBg11.svg");
    }

    .fieldsOfScience .mobile .sContainer .twelfth {
      background-image: url("/public/MBack/PBg12.svg");
    }

    .fieldsOfScience .mobile .sContainer .thirteenth {
      background-image: url("/public/MBack/PBg13.svg");
      background-size: cover !important
    }

    .fieldsOfScience .mobile .sContainer .fourtheenth {
      background-image: url("/public/MBack/PBg14.svg");
      background-size: cover !important
    }

    .fieldsOfScience .mobile .sContainer .fifteenth {
      background-image: url("/public/MBack/PBg15.svg");
      background-size: cover !important
    }

    /*  Mobile view Title   */
    .fieldsOfScience .first .m-story #title {
      background-image: url("/public/SpObjects/Tit1.svg");
    }

    .fieldsOfScience .second .m-story #title {
      background-image: url("/public/SpObjects/Tit2.svg");
    }

    .fieldsOfScience .third .m-story #title {
      background-image: url("/public/SpObjects/Tit3.svg");
    }

    .fieldsOfScience .fourth .m-story #title {
      background-image: url("/public/SpObjects/Tit4.svg");
    }

    .fieldsOfScience .fifth .m-story #title {
      background-image: url("/public/SpObjects/Tit5.svg");
    }

    .fieldsOfScience .sixth .m-story #title {
      background-image: url("/public/SpObjects/Tit6.svg");
    }

    .fieldsOfScience .seventh .m-story #title {
      background-image: url("/public/SpObjects/Tit7.svg");
    }

    .fieldsOfScience .eight .m-story #title {
      background-image: url("/public/SpObjects/Tit8.svg");
    }

    .fieldsOfScience .ninth .m-story #title {
      background-image: url("/public/SpObjects/Tit9.svg");
    }

    .fieldsOfScience .tenth .m-story #title {
      background-image: url("/public/SpObjects/Tit10.svg");
    }

    .fieldsOfScience .eleventh .m-story #title {
      background-image: url("/public/SpObjects/Tit11.svg");
    }

    .fieldsOfScience .twelfth .m-story #title {
      background-image: url("/public/SpObjects/Tit12.svg");
    }

    .fieldsOfScience .thirteenth .m-story #title {
      background-image: url("/public/SpObjects/Tit13.svg");
    }

    .fieldsOfScience .fourtheenth .m-story #title {
      background-image: url("/public/SpObjects/Tit14.svg");
    }

    .fieldsOfScience .fifteenth .m-story #title {
      background-image: url("/public/SpObjects/Tit15.svg");
    }

    .fieldsOfScience .first .m-story #title,
    .fieldsOfScience .second .m-story #title,
    .fieldsOfScience .third .m-story #title,
    .fieldsOfScience .fourth .m-story #title,
    .fieldsOfScience .fifth .m-story #title,
    .fieldsOfScience .sixth .m-story #title,
    .fieldsOfScience .seventh .m-story #title,
    .fieldsOfScience .eight .m-story #title,
    .fieldsOfScience .ninth .m-story #title,
    .fieldsOfScience .tenth .m-story #title,
    .fieldsOfScience .eleventh .m-story #title,
    .fieldsOfScience .twelfth .m-story #title,
    .fieldsOfScience .thirteenth .m-story #title,
    .fieldsOfScience .fourtheenth .m-story #title,
    .fieldsOfScience .fifteenth .m-story #title {
      background-size: auto 10%;
      background-position-x: initial;
      background-position-y: 11%;
      background-repeat: no-repeat;
      padding: 0;
      position: absolute;
      width: inherit;
      z-index: 11;
      color: black;
      background-attachment: fixed;
      height: 100%;
    }

    /*  Mobile view Img   */

    .fieldsOfScience .first .m-story #img {
      background-image: url("/public/SpObjects/Img1.svg");
    }

    .fieldsOfScience .second .m-story #img {
      background-image: url("/public/SpObjects/Img2.svg");
    }

    .fieldsOfScience .third .m-story #img {
      background-image: url("/public/SpObjects/Img3.svg");
    }

    .fieldsOfScience .fourth .m-story #img {
      background-image: url("/public/SpObjects/Img4.svg");
    }

    .fieldsOfScience .fifth .m-story #img {
      background-image: url("/public/SpObjects/Img5.svg");
    }

    .fieldsOfScience .sixth .m-story #img {
      background-image: url("/public/SpObjects/Img6.svg");
    }

    .fieldsOfScience .seventh .m-story #img {
      background-image: url("/public/SpObjects/Img7.svg");
    }

    .fieldsOfScience .eight .m-story #img {
      background-image: url("/public/SpObjects/Img8.svg");
    }

    .fieldsOfScience .ninth .m-story #img {
      background-image: url("/public/SpObjects/Img9.svg");
    }

    .fieldsOfScience .tenth .m-story #img {
      background-image: url("/public/SpObjects/Img10.svg");
    }

    .fieldsOfScience .eleventh .m-story #img {
      background-image: url("/public/SpObjects/Img11.svg");
    }

    .fieldsOfScience .twelfth .m-story #img {
      background-image: url("/public/SpObjects/Img12.svg");
    }

    .fieldsOfScience .thirteenth .m-story #img {
      background-image: url("/public/SpObjects/Img13.svg");
    }

    .fieldsOfScience .fourtheenth .m-story #img {
      background-image: url("/public/SpObjects/Img14.svg");
    }

    .fieldsOfScience .fifteenth .m-story #img {
      background-image: url("/public/SpObjects/Img15.svg");
    }

    .fieldsOfScience .first .m-story #img,
    .fieldsOfScience .second .m-story #img,
    .fieldsOfScience .third .m-story #img,
    .fieldsOfScience .fourth .m-story #img,
    .fieldsOfScience .fifth .m-story #img,
    .fieldsOfScience .sixth .m-story #img,
    .fieldsOfScience .seventh .m-story #img,
    .fieldsOfScience .eight .m-story #img,
    .fieldsOfScience .ninth .m-story #img,
    .fieldsOfScience .tenth .m-story #img,
    .fieldsOfScience .eleventh .m-story #img,
    .fieldsOfScience .twelfth .m-story #img,
    .fieldsOfScience .thirteenth .m-story #img,
    .fieldsOfScience .fourtheenth .m-story #img,
    .fieldsOfScience .fifteenth .m-story #img {
      background-position: center;
      background-size: 80%;
      background-position-y: 40%;
      background-repeat: no-repeat;
      padding: 0;
      position: absolute;
      width: inherit;
      z-index: 10;
      color: black;
      background-attachment: fixed;
      height: 100%;
    }

    /*  Mobile view Num   */

    .fieldsOfScience .first .m-story #num {
      background-image: url("/public/SpObjects/Num1.svg");
    }

    .fieldsOfScience .second .m-story #num {
      background-image: url("/public/SpObjects/Num2.svg");
    }

    .fieldsOfScience .third .m-story #num {
      background-image: url("/public/SpObjects/Num3.svg");
    }

    .fieldsOfScience .fourth .m-story #num {
      background-image: url("/public/SpObjects/Num4.svg");
    }

    .fieldsOfScience .fifth .m-story #num {
      background-image: url("/public/SpObjects/Num5.svg");
    }

    .fieldsOfScience .sixth .m-story #num {
      background-image: url("/public/SpObjects/Num6.svg");
    }

    .fieldsOfScience .seventh .m-story #num {
      background-image: url("/public/SpObjects/Num7.svg");
    }

    .fieldsOfScience .eight .m-story #num {
      background-image: url("/public/SpObjects/Num8.svg");
    }

    .fieldsOfScience .ninth .m-story #num {
      background-image: url("/public/SpObjects/Num9.svg");
    }

    .fieldsOfScience .tenth .m-story #num {
      background-image: url("/public/SpObjects/Num10.svg");
    }

    .fieldsOfScience .eleventh .m-story #num {
      background-image: url("/public/SpObjects/Num11.svg");
    }

    .fieldsOfScience .twelfth .m-story #num {
      background-image: url("/public/SpObjects/Num12.svg");
    }

    .fieldsOfScience .thirteenth .m-story #num {
      background-image: url("/public/SpObjects/Num13.svg");
    }

    .fieldsOfScience .fourtheenth .m-story #num {
      background-image: url("/public/SpObjects/Num14.svg");
    }

    .fieldsOfScience .fifteenth .m-story #num {
      background-image: url("/public/SpObjects/Num15.svg");
    }

    .fieldsOfScience .first .m-story #num,
    .fieldsOfScience .second .m-story #num,
    .fieldsOfScience .third .m-story #num,
    .fieldsOfScience .fourth .m-story #num,
    .fieldsOfScience .fifth .m-story #num,
    .fieldsOfScience .sixth .m-story #num,
    .fieldsOfScience .seventh .m-story #num,
    .fieldsOfScience .eight .m-story #num,
    .fieldsOfScience .ninth .m-story #num,
    .fieldsOfScience .tenth .m-story #num,
    .fieldsOfScience .eleventh .m-story #num,
    .fieldsOfScience .twelfth .m-story #num,
    .fieldsOfScience .thirteenth .m-story #num,
    .fieldsOfScience .fourtheenth .m-story #num,
    .fieldsOfScience .fifteenth .m-story #num {
      background-position: center;
      background-size: 30%;
      background-position-x: 15%;
      background-position-y: 60%;
      background-repeat: no-repeat;
      padding: 0;
      position: absolute;
      width: inherit;
      z-index: 10;
      color: black;
      background-attachment: fixed;
      height: 100%;
    }

    /*   Mobile View Paragraph   */

    #paraContainer {
      background-attachment: fixed !important;
      background-position: center !important;
      /* background-size: 85% 20% !important;
    overflow: auto;
    background-position-y: 85% !important;    */
      background-size: 80% 30% !important;
      background-position-y: 95% !important;
      background-repeat: no-repeat !important;
      height: 100%;
    }

    .fieldsOfScience .first .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt1.svg"); */
      background: url("/public/SpObjects/Mtxt1.svg");
    }

    .fieldsOfScience .second .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt2.svg"); */
      background: url("/public/SpObjects/Mtxt2.svg");
    }

    .fieldsOfScience .third .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt3.svg"); */
      background: url("/public/SpObjects/Mtxt3.svg");
    }

    .fieldsOfScience .fourth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt4.svg"); */
      background: url("/public/SpObjects/Mtxt4.svg");
    }

    .fieldsOfScience .fifth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt5.svg"); */
      background: url("/public/SpObjects/Mtxt5.svg");
    }

    .fieldsOfScience .sixth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt6.svg"); */
      background: url("/public/SpObjects/Mtxt6.svg");
    }

    .fieldsOfScience .seventh .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt7.svg"); */
      background: url("/public/SpObjects/Mtxt7.svg");
    }

    .fieldsOfScience .eight .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt8.svg"); */
      background: url("/public/SpObjects/Mtxt8.svg");
    }

    .fieldsOfScience .ninth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt9.svg"); */
      background: url("/public/SpObjects/Mtxt9.svg");
    }

    .fieldsOfScience .tenth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt10.svg"); */
      background: url("/public/SpObjects/Mtxt10.svg");
    }

    .fieldsOfScience .eleventh .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt11.svg"); */
      background: url("/public/SpObjects/Mtxt11.svg");
    }

    .fieldsOfScience .twelfth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt12.svg"); */
      background: url("/public/SpObjects/Mtxt12.svg");
    }

    .fieldsOfScience .thirteenth .m-story #paraContainer {
      background: url("/public/SpObjects/Mtxt13.svg");
    }

    .fieldsOfScience .fourtheenth .m-story #paraContainer {
      background: url("/public/SpObjects/Mtxt14.svg");
    }

    .fieldsOfScience .fifteenth .m-story #paraContainer {
      background: url("/public/SpObjects/Mtxt15.svg");
    }

    @media screen and (max-width: 925px) {

      .fieldsOfScience .story #title {
        background-size: auto 10% !important;
        background-position-y: 88% !important;
      }

      .fieldsOfScience .story #img {
        background-size: 55% !important;
        background-position: 50% 60% !important;
      }

      .fieldsOfScience .story #num {
        background-size: 15% !important;
        background-position: 28% 70% !important;
      }

      .fieldsOfScience .story #paraContainer {
        background-size: 80% auto !important;
        background-position: 38% 25% !important;
      }

    }

    @media screen and (max-width: 450px) {

      .fieldsOfScience #fosLogo {
        display: none;
      }

      .fieldsOfScience #dSwipe {
        display: none;
      }

      .fieldsOfScience #dSwipe {
        background-size: 40%;
        left: 45%;
      }

      .fieldsOfScience #logo {
        background-image: url("/public/logo.png");
        background-size: contain;
        /* width: 45px; */
        background-repeat: no-repeat;
      }

      .fieldsOfScience .welcome {
        display: block;
        height: 100%;
        position: inherit;
        width: 100%;
        background: url("/public/ExperiencePage/Fos.png");
        background-size: cover;
        background-position: center;
      }

      .fieldsOfScience .mobile {
        display: block;
      }

      .fieldsOfScience .desktop {
        display: none;
      }

      .fieldsOfScience .first .story div,
      .fieldsOfScience .second .story div,
      .fieldsOfScience .third .story div,
      .fieldsOfScience .fourth .story div,
      .fieldsOfScience .fifth .story div,
      .fieldsOfScience .sixth .story div,
      .fieldsOfScience .seventh .story div,
      .fieldsOfScience .eight .story div,
      .fieldsOfScience .ninth .story div,
      .fieldsOfScience .tenth .story div,
      .fieldsOfScience .eleventh .story div {
        display: none;
      }
    }
  }

  /*-------------------------- IOS devices --------------------------*/
  @supports (-webkit-overflow-scrolling: touch) {

    /* * {
      margin: 0;
      height: 100%;
    }
   */
    html {
      scroll-behavior: smooth;
    }


    body,
    html {
      height: 100%;
      overflow-x: clip;
      width: -webkit-fill-available;
    }

    .fieldsOfScience .top-nav {
      position: fixed;
      top: 0;
      width: 100%;
      background-color: transparent;
      height: 50px;
      z-index: 1002;
    }

    .top-nav ul {
      display: flex;
      gap: 34px;
      list-style-type: none;
      margin-left: 6.7%;
      margin-top: 12px;
      padding: 0;
      text-align: left;
    }

    .top-nav li {
      display: inline;
    }

    .top-nav a {
      display: inline-block;
      font-family: 'DM Sans';
      color: #fff;

      text-transform: capitalize;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
      transition: 0.3s;
      font-size: 17px;
    }

    #logo {
      background-image: url("/public/logo.png");
      background-size: contain;
      /* width: 65px;
      height: 66px; */
      background-repeat: no-repeat;
      /* display: none; */
    }

    .welcome {
      z-index: 1001;
      position: absolute;
      display: none;
    }

    #fosLogo {
      background-image: url("/public/FOSLogo.svg");
      background-position: center;
      background-size: 50%;
      position: fixed;
      width: 20%;
      height: 11%;
      left: 40%;
      background-repeat: no-repeat;
      z-index: 3;
    }

    #dSwipe {
      background: url("/public/swipe.svg");
      background-position: center;
      background-size: 90%;
      position: fixed;
      width: 42px;
      height: 40px;
      left: 48.5%;
      background-repeat: no-repeat;
      bottom: 4%;
      z-index: 3;
    }

    .sContainer {
      height: 100vh;
      width: 100%;
    }

    .first {
      background-image: url('/public/Back/Bg1.svg');
      background-color: #352b5f;
    }

    .first .story #title {
      background: url('/public/SpObjects/Tit1.svg');
      /* background-color: #352b5f; */
    }

    .first .story #img {
      background: url('/public/SpObjects/Img1.svg');
    }

    .first .story #num {
      background: url('/public/SpObjects/Num1.svg');
    }

    .first .story #paraContainer {
      background: url('/public/SpObjects/Dtxt1.svg');
    }

    .second {
      background-image: url("/public/Back/Bg2.svg");
      background-color: #e5e3f1;
    }

    .second .story #title {
      background: url('/public/SpObjects/Tit2.svg');
    }

    .second .story #img {
      background: url('/public/SpObjects/Img2.svg');
    }

    .second .story #num {
      background: url('/public/SpObjects/Num2.svg');
    }

    .second .story #paraContainer {
      background: url('/public/SpObjects/Dtxt2.svg');
    }

    .third {
      background-image: url('/public/Back/Bg3.svg');
      background-color: #eae5f1;
    }

    .third .story #title {
      background: url('/public/SpObjects/Tit3.svg');
    }

    .third .story #img {
      background: url('/public/SpObjects/Img3.svg');
    }

    .third .story #num {
      background: url('/public/SpObjects/Num3.svg');
    }

    .third .story #paraContainer {
      background: url('/public/SpObjects/Dtxt3.svg');
    }

    .fourth {
      background-image: url("/public/Back/Bg4.svg");
      background-color: #ffd2c5;
    }

    .fourth .story #title {
      background: url('/public/SpObjects/Tit4.svg');
    }

    .fourth .story #img {
      background: url('/public/SpObjects/Img4.svg');
    }

    .fourth .story #num {
      background: url('/public/SpObjects/Num4.svg');
    }

    .fourth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt4.svg');
    }

    .fifth {
      background-image: url("/public/Back/Bg5.svg");
      background-color: #ffd2c5;
    }

    .fifth .story #title {
      background: url('/public/SpObjects/Tit5.svg');
    }

    .fifth .story #img {
      background: url('/public/SpObjects/Img5.svg');
    }

    .fifth .story #num {
      background: url('/public/SpObjects/Num5.svg');
    }

    .fifth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt5.svg');
    }

    .sixth {
      background-image: url("/public/Back/Bg6.svg");
      background-color: #ffd2c5;
    }

    .sixth .story #title {
      background: url('/public/SpObjects/Tit6.svg');
    }

    .sixth .story #img {
      background: url('/public/SpObjects/Img6.svg');
    }

    .sixth .story #num {
      background: url('/public/SpObjects/Num6.svg');
    }

    .sixth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt6.svg');
    }

    .seventh {
      background-image: url("/public/Back/Bg7.svg");
      background-color: #ffd2c5;
    }

    .seventh .story #title {
      background: url('/public/SpObjects/Tit7.svg');
    }

    .seventh .story #img {
      background: url('/public/SpObjects/Img7.svg');
    }

    .seventh .story #num {
      background: url('/public/SpObjects/Num7.svg');
    }

    .seventh .story #paraContainer {
      background: url('/public/SpObjects/Dtxt7.svg');
    }

    .eight {
      background-image: url("/public/Back/Bg8.svg");
      background-color: #ffd2c5;
    }

    .eight .story #title {
      background: url('/public/SpObjects/Tit8.svg');
    }

    .eight .story #img {
      background: url('/public/SpObjects/Img8.svg');
    }

    .eight .story #num {
      background: url('/public/SpObjects/Num8.svg');
    }

    .eight .story #paraContainer {
      background: url('/public/SpObjects/Dtxt8.svg');
    }

    .ninth {
      background-image: url("/public/Back/Bg9.svg");
      background-color: #ffd2c5;
    }

    .ninth .story #title {
      background: url('/public/SpObjects/Tit9.svg');
    }

    .ninth .story #img {
      background: url('/public/SpObjects/Img9.svg');
    }

    .ninth .story #num {
      background: url('/public/SpObjects/Num9.svg');
    }

    .ninth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt9.svg');
    }

    .tenth {
      background-image: url("/public/Back/Bg10.svg");
      background-color: #ffd2c5;
    }

    .tenth .story #title {
      background: url('/public/SpObjects/Tit10.svg');
    }

    .tenth .story #img {
      background: url('/public/SpObjects/Img10.svg');
    }

    .tenth .story #num {
      background: url('/public/SpObjects/Num10.svg');
    }

    .tenth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt10.svg');
    }

    .eleventh {
      background-image: url("/public/Back/Bg11.svg");
      background-color: #ffd2c5;
    }

    .eleventh .story #title {
      background: url('/public/SpObjects/Tit11.svg');
    }

    .eleventh .story #img {
      background: url('/public/SpObjects/Img11.svg');
    }

    .eleventh .story #num {
      background: url('/public/SpObjects/Num11.svg');
    }

    .eleventh .story #paraContainer {
      background: url('/public/SpObjects/Dtxt11.svg');
    }

    .twelfth {
      background-image: url("/public/Back/Bg12.svg");
      background-color: #ffd2c5;
    }

    .twelfth .story #title {
      background: url('/public/SpObjects/Tit12.svg');
    }

    .twelfth .story #img {
      background: url('/public/SpObjects/Img12.svg');
    }

    .twelfth .story #num {
      background: url('/public/SpObjects/Num12.svg');
    }

    .twelfth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt12.svg');
    }

    .thirteenth {
      background-image: url("/public/Back/Bg13.svg");
      background-color: #ffd2c5;
    }

    .thirteenth .story #title {
      background: url('/public/SpObjects/Tit13.svg');
    }

    .thirteenth .story #img {
      background: url('/public/SpObjects/Img13.svg');
    }

    .thirteenth .story #num {
      background: url('/public/SpObjects/Num13.svg');
    }

    .thirteenth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt13.svg');
    }

    .fourtheenth {
      background-image: url("/public/Back/Bg14.svg");
      background-color: #ffd2c5;
    }

    .fourtheenth .story #title {
      background: url('/public/SpObjects/Tit14.svg');
    }

    .fourtheenth .story #img {
      background: url('/public/SpObjects/Img14.svg');
    }

    .fourtheenth .story #num {
      background: url('/public/SpObjects/Num14.svg');
    }

    .fourtheenth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt14.svg');
    }

    .fifteenth {
      background-image: url("/public/Back/Bg15.svg");
      background-color: #ffd2c5;
    }

    .fifteenth .story #title {
      background: url('/public/SpObjects/Tit15.svg');
    }

    .fifteenth .story #img {
      background: url('/public/SpObjects/Img15.svg');
    }

    .fifteenth .story #num {
      background: url('/public/SpObjects/Num15.svg');
    }

    .fifteenth .story #paraContainer {
      background: url('/public/SpObjects/Dtxt15.svg');
    }

    .first,
    .second,
    .third,
    .fourth,
    .fifth,
    .sixth,
    .seventh,
    .eight,
    .ninth,
    .tenth,
    .eleventh,
    .twelfth,
    .thirteenth,
    .fourtheenth,
    .fifteenth {
      display: flex;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      position: relative;
      overflow: hidden;
      z-index: 2;
    }

    .story {
      margin: 0 auto;
      min-width: 980px;
      width: 100%;
      position: relative;
    }

    .story #title {
      background-repeat: no-repeat !important;
      /* background-attachment: scroll !important; */
      background-size: auto 20% !important;
      background-position-y: 76% !important;
      z-index: 2;
      height: 100%;
    }

    .story #img {
      background-repeat: no-repeat !important;
      background-attachment: scroll !important;
      background-size: 32% !important;
      background-position: 73.2% 68% !important;
      height: 100%;
    }

    .story #num {
      background-repeat: no-repeat !important;
      background-attachment: scroll !important;
      background-size: 10% !important;
      background-position: 50.2% 74% !important;
      height: 100%;
    }

    .story #paraContainer {
      background-repeat: no-repeat !important;
      background-attachment: scroll !important;
      background-size: 35% !important;
      background-position: 11% 33% !important;
      z-index: 1;
      height: 100%;
    }

    .story div {
      position: absolute;
      width: inherit;
    }

    .eleventh .story #num {
      background-size: 9% !important;
    }

    .side-nav {
      position: fixed;
      right: 8%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;
    }

    .side-nav ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .side-nav ul li {
      margin-bottom: 20px;
      height: 15px;
    }

    .side-nav ul li a {
      display: block;
      background: #AD7B10 0% 0% no-repeat padding-box;
      height: 12px;
      width: 12px;
      opacity: 0.5;
      border-radius: 50%;
      text-align: center;
      line-height: 15px;
      color: #fff;
      text-decoration: none;
    }

    .side-nav ul li a p {
      color: #AD7B10;
      font-family: 'DM Sans';
      font-size: 18px;
      position: relative;
      /* top: -14px; */
      text-align: end;
      width: 122px;
      height: 29px;
      left: -91px;
    }

    .side-nav ul li a p {
      transition: transform 0.5s ease, opacity 0.5s ease;
      /* Adjust the timing as needed */
      transform: translateX(0);
      /* Adjust the distance as needed */
      opacity: 0;
    }

    .side-nav ul li a:hover p {
      transform: translateX(-45px);
      opacity: 1;
    }


    .side-nav ul li a:hover {
      opacity: 1 !important;
    }

    /* active class */
    .side-nav ul li a.active {
      opacity: 1;
    }

    .active {
      opacity: 1;
    }

    .mobile {
      display: none;
    }

    .m-story {
      margin: 0 auto;
      overflow: auto;
      width: 100%;
    }

    /******************************************  Mobile view  ******************************************/

    .mobile .sContainer .first {
      background-image: url("/public/MBack/PBg1.svg");
    }

    .mobile .sContainer .second {
      background-image: url("/public/MBack/PBg2.svg");
    }

    .mobile .sContainer .third {
      background-image: url("/public/MBack/PBg3.svg");
    }

    .mobile .sContainer .fourth {
      background-image: url("/public/MBack/PBg4.svg");
    }

    .mobile .sContainer .fifth {
      background-image: url("/public/MBack/PBg5.svg");
    }

    .mobile .sContainer .sixth {
      background-image: url("/public/MBack/PBg6.svg");
    }

    .mobile .sContainer .seventh {
      background-image: url("/public/MBack/PBg7.svg");
    }

    .mobile .sContainer .eight {
      background-image: url("/public/MBack/PBg8.svg");
    }

    .mobile .sContainer .ninth {
      background-image: url("/public/MBack/PBg9.svg");
    }

    .mobile .sContainer .tenth {
      background-image: url("/public/MBack/PBg10.svg");
    }

    .mobile .sContainer .eleventh {
      background-image: url("/public/MBack/PBg11.svg");
    }

    .mobile .sContainer .twelfth {
      background-image: url("/public/MBack/PBg12.svg");
    }

    .mobile .sContainer .thirteenth {
      background-image: url("/public/MBack/PBg13.svg");
      /* background-size: cover !important */
    }

    .mobile .sContainer .fourtheenth {
      background-image: url("/public/MBack/PBg14.svg");
      /* background-size: cover !important */
    }

    .mobile .sContainer .fifteenth {
      background-image: url("/public/MBack/PBg15.svg");
      /* background-size: cover !important */
    }

    /*  Mobile view Title   */
    .first .m-story #title {
      background-image: url("/public/SpObjects/Tit1.svg");
    }

    .second .m-story #title {
      background-image: url("/public/SpObjects/Tit2.svg");
    }

    .third .m-story #title {
      background-image: url("/public/SpObjects/Tit3.svg");
    }

    .fourth .m-story #title {
      background-image: url("/public/SpObjects/Tit4.svg");
    }

    .fifth .m-story #title {
      background-image: url("/public/SpObjects/Tit5.svg");
    }

    .sixth .m-story #title {
      background-image: url("/public/SpObjects/Tit6.svg");
    }

    .seventh .m-story #title {
      background-image: url("/public/SpObjects/Tit7.svg");
    }

    .eight .m-story #title {
      background-image: url("/public/SpObjects/Tit8.svg");
    }

    .ninth .m-story #title {
      background-image: url("/public/SpObjects/Tit9.svg");
    }

    .tenth .m-story #title {
      background-image: url("/public/SpObjects/Tit10.svg");
    }

    .eleventh .m-story #title {
      background-image: url("/public/SpObjects/Tit11.svg");
    }

    .twelfth .m-story #title {
      background-image: url("/public/SpObjects/Tit12.svg");
    }

    .thirteenth .m-story #title {
      background-image: url("/public/SpObjects/Tit13.svg");
    }

    .fourtheenth .m-story #title {
      background-image: url("/public/SpObjects/Tit14.svg");
    }

    .fifteenth .m-story #title {
      background-image: url("/public/SpObjects/Tit15.svg");
    }

    .first .m-story #title,
    .second .m-story #title,
    .third .m-story #title,
    .fourth .m-story #title,
    .fifth .m-story #title,
    .sixth .m-story #title,
    .seventh .m-story #title,
    .eight .m-story #title,
    .ninth .m-story #title,
    .tenth .m-story #title,
    .eleventh .m-story #title,
    .twelfth .m-story #title,
    .thirteenth .m-story #title,
    .fourtheenth .m-story #title,
    .fifteenth .m-story #title {
      background-size: auto 10%;
      background-position-x: initial;
      background-position-y: 11%;
      background-repeat: no-repeat;
      padding: 0;
      height: 100%;
      position: absolute;
      width: inherit;
      z-index: 11;
      color: black;
      background-attachment: scroll !important;
    }

    /*  Mobile view Img   */

    .first .m-story #img {
      background-image: url("/public/SpObjects/Img1.svg");
    }

    .second .m-story #img {
      background-image: url("/public/SpObjects/Img2.svg");
    }

    .third .m-story #img {
      background-image: url("/public/SpObjects/Img3.svg");
    }

    .fourth .m-story #img {
      background-image: url("/public/SpObjects/Img4.svg");
    }

    .fifth .m-story #img {
      background-image: url("/public/SpObjects/Img5.svg");
    }

    .sixth .m-story #img {
      background-image: url("/public/SpObjects/Img6.svg");
    }

    .seventh .m-story #img {
      background-image: url("/public/SpObjects/Img7.svg");
    }

    .eight .m-story #img {
      background-image: url("/public/SpObjects/Img8.svg");
    }

    .ninth .m-story #img {
      background-image: url("/public/SpObjects/Img9.svg");
    }

    .tenth .m-story #img {
      background-image: url("/public/SpObjects/Img10.svg");
    }

    .eleventh .m-story #img {
      background-image: url("/public/SpObjects/Img11.svg");
    }

    .twelfth .m-story #img {
      background-image: url("/public/SpObjects/Img12.svg");
    }

    .thirteenth .m-story #img {
      background-image: url("/public/SpObjects/Img13.svg");
    }

    .fourtheenth .m-story #img {
      background-image: url("/public/SpObjects/Img14.svg");
    }

    .fifteenth .m-story #img {
      background-image: url("/public/SpObjects/Img15.svg");
    }

    .first .m-story #img,
    .second .m-story #img,
    .third .m-story #img,
    .fourth .m-story #img,
    .fifth .m-story #img,
    .sixth .m-story #img,
    .seventh .m-story #img,
    .eight .m-story #img,
    .ninth .m-story #img,
    .tenth .m-story #img,
    .eleventh .m-story #img,
    .twelfth .m-story #img,
    .thirteenth .m-story #img,
    .fourtheenth .m-story #img,
    .fifteenth .m-story #img {
      background-position: center;
      background-size: 80%;
      background-position-y: 40%;
      background-repeat: no-repeat;
      padding: 0;
      position: absolute;
      height: 100%;
      width: inherit;
      z-index: 10;
      color: black;
      background-attachment: scroll !important;
    }

    /*  Mobile view Num   */

    .first .m-story #num {
      background-image: url("/public/SpObjects/Num1.svg");
    }

    .second .m-story #num {
      background-image: url("/public/SpObjects/Num2.svg");
    }

    .third .m-story #num {
      background-image: url("/public/SpObjects/Num3.svg");
    }

    .fourth .m-story #num {
      background-image: url("/public/SpObjects/Num4.svg");
    }

    .fifth .m-story #num {
      background-image: url("/public/SpObjects/Num5.svg");
    }

    .sixth .m-story #num {
      background-image: url("/public/SpObjects/Num6.svg");
    }

    .seventh .m-story #num {
      background-image: url("/public/SpObjects/Num7.svg");
    }

    .eight .m-story #num {
      background-image: url("/public/SpObjects/Num8.svg");
    }

    .ninth .m-story #num {
      background-image: url("/public/SpObjects/Num9.svg");
    }

    .tenth .m-story #num {
      background-image: url("/public/SpObjects/Num10.svg");
    }

    .eleventh .m-story #num {
      background-image: url("/public/SpObjects/Num11.svg");
    }

    .twelfth .m-story #num {
      background-image: url("/public/SpObjects/Num12.svg");
    }

    .thirteenth .m-story #num {
      background-image: url("/public/SpObjects/Num13.svg");
    }

    .fourtheenth .m-story #num {
      background-image: url("/public/SpObjects/Num14.svg");
    }

    .fifteenth .m-story #num {
      background-image: url("/public/SpObjects/Num15.svg");
    }

    .first .m-story #num,
    .second .m-story #num,
    .third .m-story #num,
    .fourth .m-story #num,
    .fifth .m-story #num,
    .sixth .m-story #num,
    .seventh .m-story #num,
    .eight .m-story #num,
    .ninth .m-story #num,
    .tenth .m-story #num,
    .eleventh .m-story #num,
    .twelfth .m-story #num,
    .thirteenth .m-story #num,
    .fourtheenth .m-story #num,
    .fifteenth .m-story #num {
      background-position: center;
      background-size: 30%;
      background-position-x: 15%;
      background-position-y: 60%;
      background-repeat: no-repeat;
      padding: 0;
      height: 100%;
      position: absolute;
      width: inherit;
      z-index: 10;
      color: black;
      background-attachment: scroll !important;
    }

    /*   Mobile View Paragraph   */

    #paraContainer {
      background-attachment: scroll !important;
      background-position: center !important;
      /* background-size: 85% 20% !important;
    overflow: auto;
    background-position-y: 85% !important;    */
      background-size: 80% 30% !important;
      background-position-y: 95% !important;
      background-repeat: no-repeat !important;
      height: 100% !important;
    }

    .first .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt1.svg"); */
      background: url("/public/SpObjects/Mtxt1.svg");
    }

    .second .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt2.svg"); */
      background: url("/public/SpObjects/Mtxt2.svg");
    }

    .third .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt3.svg"); */
      background: url("/public/SpObjects/Mtxt3.svg");
    }

    .fourth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt4.svg"); */
      background: url("/public/SpObjects/Mtxt4.svg");
    }

    .fifth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt5.svg"); */
      background: url("/public/SpObjects/Mtxt5.svg");
    }

    .sixth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt6.svg"); */
      background: url("/public/SpObjects/Mtxt6.svg");
    }

    .seventh .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt7.svg"); */
      background: url("/public/SpObjects/Mtxt7.svg");
    }

    .eight .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt8.svg"); */
      background: url("/public/SpObjects/Mtxt8.svg");
    }

    .ninth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt9.svg"); */
      background: url("/public/SpObjects/Mtxt9.svg");
    }

    .tenth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt10.svg"); */
      background: url("/public/SpObjects/Mtxt10.svg");
    }

    .eleventh .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt11.svg"); */
      background: url("/public/SpObjects/Mtxt11.svg");
    }

    .twelfth .m-story #paraContainer {
      /* background: url("/public/DesktopTxt/Txt12.svg"); */
      background: url("/public/SpObjects/Mtxt12.svg");
    }

    .thirteenth .m-story #paraContainer {
      background: url("/public/SpObjects/Mtxt13.svg");
    }

    .fourtheenth .m-story #paraContainer {
      background: url("/public/SpObjects/Mtxt14.svg");
    }

    .fifteenth .m-story #paraContainer {
      background: url("/public/SpObjects/Mtxt15.svg");
    }

    .welcome #disclaimer {
      display: flex;
      color: white;
      position: absolute;
      bottom: 10%;
      width: 90%;
    }

    @media screen and (max-width: 1024px) {
      .cardsContainer div div {
        width: 25%;
      }
    }

    @media screen and (max-width: 925px) {

      #ipadDisclaimer {
        color: white;
        position: fixed;
        width: 40%;
        z-index: 3;
        top: 90%;
        right: 0;
      }

      .story #title {
        background-size: auto 10% !important;
        background-position-y: 88% !important;
      }

      .story #img {
        background-size: 50% !important;
        background-position: 50% 60% !important;
      }

      .story #num {
        background-size: 15% !important;
        background-position: 28% 70% !important;
      }

      .story #paraContainer {
        background-size: 65% auto !important;
        background-position: 25% 25% !important;
      }

    }

    @media screen and (max-width: 450px) {

      #ipadDisclaimer {
        display: none;
        /* color: white;
        position: fixed;
        width: 40%;
        z-index: 3;
        top: 90%;
        right: 0; */
      }

      #fosLogo {
        display: none;
      }

      #dSwipe {
        display: none;
      }

      #dSwipe {
        background-size: 40%;
        left: 45%;
      }

      #logo {
        background-image: url("/public/logo.png");
        background-size: contain;
        /* width: 45px; */
        background-repeat: no-repeat;
      }

      .welcome {
        display: flex;
        height: 100%;
        position: inherit;
        width: 100%;
        background: url("/public/ExperiencePage/Fos.png");
        background-size: cover;
        background-position: center;
        justify-content: center;
        text-align: justify;
      }

      .mobile {
        display: block;
      }

      .desktop {
        display: none;
      }

      .first .story div,
      .second .story div,
      .third .story div,
      .fourth .story div,
      .fifth .story div,
      .sixth .story div,
      .seventh .story div,
      .eight .story div,
      .ninth .story div,
      .tenth .story div,
      .eleventh .story div {
        display: none;
      }
    }
  }