.evolution-sci .loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: black;
}

.evolution-sci .loader svg {
  width: 100px;
  height: 100px;
  margin: 20px;
  display: inline-block;
}
