/* SIDEBAR CSS */
.parsec.nav .btn {
  position: absolute;
  width: 20px;
  cursor: pointer;
  height: 30%;
  right: 8%;
  display: flex;
  align-items: flex-end;
}

.parsec.nav .btn span {
  display: block;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  height: 3px;
  background: #fff;
  transition: all 0.3s;
  position: relative;
}

.parsec.nav .active span:nth-child(1) {
  animation: ease 0.7s top forwards;
}

.parsec.nav .not-active span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
}

.parsec.nav .active span:nth-child(2) {
  animation: ease 0.7s scaled forwards;
}

.parsec.nav .not-active span:nth-child(2) {
  animation: ease 0.7s scaled-2 forwards;
  width: 80%;
}

.parsec.nav .active span:nth-child(3) {
  animation: ease 0.7s bottom forwards;
}

.parsec.nav .not-active span:nth-child(3) {
  animation: ease 0.7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 0px;
    transform: rotate(0);
  }
  100% {
    top: -6.5px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: -6.5px;
    transform: rotate(45deg);
  }
  50% {
    top: 0px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 0px;
    transform: rotate(0);
  }
  100% {
    bottom: -6.5px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: -6.5px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 0px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.parsec.nav .sideBar {
  position: fixed;
  height: 100vh;
  width: 70vw;
  top: 0;
  right: -100vw;
  display: flex !important;
  flex-direction: column;
  background-color: #000000;
  padding: 15% 0 0 10%;
  justify-content: normal;
  gap: 3%;
  transition: 0.5s ease-in-out;
  /* z-index: 2; */
}

.parsec.nav .sidebarButton {
  /* display: none !important; */
  display: flex !important;
  /* position: fixed; */
  right: 8%;
  z-index: 2;
  gap: 3%;
}

.parsec.nav .sideBar div {
  font-size: 1.78vh;
}

.parsec.nav .sideBar a {
  text-decoration: none;
  color: white;
}
/* SIDEBAR CSS */

.parsecGallery.nav {
  justify-content: space-between;
  padding: 1% 4%;
  height: auto;
  background-color: #eeeff1;
}

.parsec.nav {
  justify-content: space-between;
  padding: 1% 4%;
  height: auto;
  background-color: #0b0c10;
  position: fixed;
}

.parsec.nav a {
  text-decoration: none;
  color: white;
}

.parsec.nav .visitUsOrBook {
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
  align-items: center;
  gap: 5%;
  padding-right: 2%;
}

.parsec.nav .visitUsOrBook .visitUs {
  width: max-content;
  font-size: 1.04vw;
  line-height: 1vw;
  font-weight: bold;
}

.parsec.nav #logo {
  background-image: url(/public/logo.png);
}

.parsecGallery.nav .bookTicket,
.parsec.nav .bookTicket {
  background-color: #D7326E;
  border-radius: 200px;
  font-size: 1.04vw;
  padding: 1.5% 2.5%;
  letter-spacing: 0.05vw;
  font-weight: bold;
  color: rgb(255, 255, 255);
  cursor: pointer;
  text-transform: uppercase;
  z-index: 1005;
}

@media only screen and (max-width: 450px) {
  .parsec.nav .bookTicket {
    font-size: 1.4vh;
    width: max-content;
    padding: 5% 8%;
    border-radius: 20px;
    letter-spacing: 0.08vh;
  }
}
