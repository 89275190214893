.exhibitPage {
  background-color: #fafafa;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.exhibitPage .exhibitPageContent {
  width: 80%;
  padding-top: 4%;
  padding-bottom: 10%;
}

.exhibitPage h1,
.exhibitPage p {
  font-family: "Gill Sans", sans-serif;
}

.exhibitPage h3 {
  font-size: 1.4vw;
  font-weight: 600;
  letter-spacing: 0.14vw;
}

.exhibitPage .exhibitPageContent .exhibitPageHead h1 {
  font-weight: 600;
  font-size: 2.6vw;
  letter-spacing: 0.78vw;
}

.exhibitPage .exhibitPageContent .exhibitPageHead p,
.exhibitPage p,
.exhibitPage div {
  color: black;
  font-size: 1.4vw;
  letter-spacing: 0.09vw;
  font-weight: 300;
}

.exhibitPage .exhibitPageContent .exhibitPageHead h1,
.exhibitPage .exhibitPageContent .exhibitPageHead p {
  text-align: center;
}

.exhibitPage .exhibitPageContent .exhibitPageHead .exhibitPageHeadBanner {
  width: 100%;
  height: 50vh;
  background-size: 100% auto;
  background-position: center;
}

.exhibitPage .exhibitPageContent .exhibitPageSubcontent {
  display: flex;
  gap: 10%;
  justify-content: space-between;
  padding-top: 4%;
}

.exhibitPage .exhibitPageContent .exhibitPageSubcontent .exhibitPageData .exhibitPageScienceRelevancy {
    padding-top: 6%;
}

.exhibitPage .exhibitPageContent .exhibitPageSubcontent .exhibitPageData,
.exhibitPage
.exhibitPageContent
.exhibitPageSubcontent
.exhibitPageSubcontentBanner {
  width: 50%;
}

.exhibitPage .exhibitPageContent .exhibitPageSubcontent .exhibitPageData .exhibitPageKeyTakeAway ul li p {
  margin: 0;
}

.exhibitPage
  .exhibitPageContent
  .exhibitPageSubcontent
  .exhibitPageSubcontentBanner {
  background-size: 100%;
  height: 32.6vh;
}

.exhibitPage
  .exhibitPageContent
  .exhibitPageSubcontent
  .exhibitPageData
  .exhibitPageKeywords
  .exhibitPageKeywordsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  row-gap: 1vw;
}

.exhibitPage
  .exhibitPageContent
  .exhibitPageSubcontent
  .exhibitPageData
  .exhibitPageKeywords
  .exhibitPageKeywordsContainer
  div {
  border: 0.1px solid black;
  box-shadow: 0px 3px 10px #00000070;
  padding: 2%;
}

@media only screen and (max-width: 450px) {
  .exhibitPage .exhibitPageContent .exhibitPageHead .exhibitPageHeadBanner,
  .exhibitPage
    .exhibitPageContent
    .exhibitPageSubcontent
    .exhibitPageSubcontentBanner {
    height: 20vh;
  }

  .exhibitPage .exhibitPageContent .exhibitPageSubcontent .exhibitPageData,
  .exhibitPage
    .exhibitPageContent
    .exhibitPageSubcontent
    .exhibitPageSubcontentBanner {
    width: 100%;
  }

  .exhibitPage .exhibitPageContent .exhibitPageHead h1 {
    font-size: 2.6vh;
    letter-spacing: 0.2vh;
  }

  .exhibitPage h3 {
    font-size: 1.4vh;
    letter-spacing: 0.14vh;
  }

  .exhibitPage .exhibitPageContent .exhibitPageHead p,
  .exhibitPage p,
  .exhibitPage div {
    font-size: 1.4vh;
    letter-spacing: 0.09vh;
  }

  .exhibitPage
    .exhibitPageContent
    .exhibitPageSubcontent
    .exhibitPageData
    .exhibitPageKeywords
    .exhibitPageKeywordsContainer {
    row-gap: 1vh;
    padding-bottom: 8%;
  }

  .exhibitPage
    .exhibitPageContent
    .exhibitPageSubcontent
    .exhibitPageData
    .exhibitPageKeywords
    .exhibitPageKeywordsContainer
    div {
    padding: 3%;
    /* border-radius: 20px; */
  }

  .exhibitPage .exhibitPageContent .exhibitPageYtLink p a {
    overflow-wrap: anywhere;
  }
}
