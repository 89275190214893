.funScience {
  padding-top: 7%;
  padding-bottom: 10%;
  width: 100%;
  background: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  overflow: hidden;
  gap: 2em;
}

.funScience .headContent {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.funScience .headContent h1 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.funScience .headContent p {
  width: auto;
  /* text-align: justify; */
}

.funScience .cards-table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  height: 100%;
}

.funScience .cards-table a {
  width: 100%;
  display: flex;
}

.funScience .card {
  position: relative;
  width: 20vw;
  height: 33vh;
  /* overflow: hidden; */
  /* border: 1px solid #ddd; */
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
}

.funScience .card img {
  width: 100%;
  transition: transform 0.3s ease;
  height: 100%;
  object-fit: cover;

  /* width: 95%;
    transition: transform 0.3s ease;
    height: 85%;
    object-fit: cover;
    position: relative;
    top: 2%;
    border-radius: 15px; */
}

.funScience .card:hover img {
  transform: scale(1.1);
}

.funScience .card .content {
  position: absolute;
  bottom: 0%;
  /* left: 2%; */
  background-color: rgb(0 0 0 / 20%);
  backdrop-filter: blur(20px);
  width: 100%;
  text-align: left;
  padding: 2%;
}

.funScience .card .content span {
  color: white;
  padding-left: 2%;
}

.funScience .hover-text {
  position: absolute;
  bottom: 11%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;

  /* Updated styles to make the div expand based on content size */
  max-width: 200%;
  /* Limit the width to its parent's width */
  overflow: hidden;
  /* Hide overflowing content */

  /* Prevent text from wrapping to the next line */
  white-space: nowrap;
}

.funScience .card:hover .hover-text {
  position: absolute;
  visibility: visible;
  opacity: 1;
  /* width: 40vw; */
}

.funScience .card .hover-text:hover .card {
  overflow: visible !important;
  background-color: black;
}

@media only screen and (max-width: 800px) {
  .cards-table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding-top: 10%;
  }
}

@media only screen and (max-width: 1025px) {
  .funScience .cards-table {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .funScience .headContent p {
    width: 90%;
  }

  .funScience .headContent {
    text-align: center;
  }

  .funScience .cards-table {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    width: 100%;
    justify-items: center;
  }

  .funScience .cards-table a {
    width: 100%;
    justify-content: center;
  }

  .funScience .card {
    width: 90%;
    /* height: 35%; */
  }

  .funScience {
    padding-top: 20%;
  }
}