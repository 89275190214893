/* DialogBox.css */
.ScienceAplication .dialog-container {
    position: fixed;
    top:10%;
    left: 25%;
    width: 50%;
    background-color:black;
    border-top: 1px solid #000;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    color: white;
  }
  
  .ScienceAplication .dialog-container.show {
    transform: translateY(0%);
  }
  
  .ScienceAplication .dialog-container.hide {
    /* transform: translateY(-200%); */
    display: none;
  }
  .ScienceAplication .dialog-content {
    padding: 10px;
    display: flex;
    justify-content: center;
     font-size: 1.5vw;
  }
  
  
  .ScienceAplication .loader{

    position: relative;

    width: 100%;

    height: 2px;

    background: #000;

}
.ScienceAplication .loader::before{

    content: '';

    position: absolute;

    width: 100%;

    height: 100%;

    background:white;

    transform-origin: left;

    animation: animate 10s linear infinite;

}

@keyframes animate{

    0%,20%{

        transform: scaleX(0);

    }

    40%{

        transform: scaleX(1);

        transform-origin: left;

    }

    40.000001%,60%{

        transform: scaleX(1);

        transform-origin: right;

    }

    80%,100%{

        transform: scaleX(0);

        transform-origin: right;

    }

}

.ScienceAplication  .loader::after{

    content: '';

    position: absolute;

    top: 50%;

    transform: translateY(-50%) scale(0);

    width: 10px;

    height: 10px;

    background: #0bec7c;

    border-radius: 50%;



}

@media screen and (max-width: 427px) {
    .ScienceAplication .dialog-content {
      
         font-size: 2.5vw;
      }
      
  }
